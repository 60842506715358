import React, { useEffect, useState } from "react";
import Header from "../../Common/Header";
import NavBar from "../../Navbar/NavBar";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import moment from "moment";
import Modal from 'react-modal'
import { deleteAPI, getAPI, postAPI } from "../../utils/api";
import { Link } from "react-router-dom";
import { trimDate } from "../../utils/helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomEvents = () => {
  const [loader, setLoader] = useState(false);
  const [scheduleLoader,setScheduleLoader] = useState(false)
  const [modalLoader, setModalLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState()
  const [fightLoader, setFightLoader] = useState()
  const [eventName, setEventName] = useState()
  const [eventDate, setEventDate] = useState()
  const [modalEventName, setModalEventName] = useState()
  const [modalEventDate, setModalEventDate] = useState()
  const [eventData, setEventData] = useState()
  const [showModal, setShowModal] = useState(false)
  const [ModalIs, setModalIs] = useState(false)
  const [id, setId] = useState()
  const [nameErr, setNameErr]= useState()
  const [dateErr, setDateErr] = useState()
  const [editModalNameErr, setEditModalNameErr] = useState()
  const [editModalDateErr, setEditModalDateErr] = useState()
  const [duplicateMsg,setDuplicateMsg] = useState()
  const [fightDate, setFightDate] = useState()
  const [fighterData, setFighterData] = useState()
  const [checkedData, setCheckedData] = useState([])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      borderColor: "neutral10",
      "&:hover": {
        borderColor: "neutral10 !important",
      },
    }),
    overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: "800px",
      width: "100%",
      maxHeight: "80vh",
    }
  };
  const handleModalClose=()=>{
    setShowModal(false);
    setModalIs(false)
    setFighterData('')
    setFightDate('')
  }
  
  const submitHandler = (e) => {
    e.preventDefault()
    setLoader(true)
    const data = {eventDate, eventName}
    if(eventDate && eventName){
      postAPI(`/event/addEvent`,data).then((res)=>{
        if(res.status === 200){
          if(res.data.status === 1){
            getData()
            setLoader(false)
            setEventName('')
            setEventDate('')
          }else{
            setLoader(false)
            setDuplicateMsg({msg:res.data.message})
          }
        }
      })
    }else{
      setLoader(false)
      if(!eventDate){
        setDateErr({msg:'Required'})
       }
       if(!eventName){
         setNameErr({msg:'Required'})
       }
    }
  }

  const getSechdule = (e) => {
    e.preventDefault()
    setScheduleLoader(true)
    getAPI('/rundown/getSchedule').then(res=>{
      console.log(res.data)
      if(res.data?.status) {
        setScheduleLoader(false)
        toast.success("Schedules updated Successfully", { position: "top-right", autoClose: 3000 })
      }
      else {
        setScheduleLoader(false)
        toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
      }
      setScheduleLoader(false)
    }).catch(e=>{
      setScheduleLoader(false)
      console.log(e)
    })
  }

  const handleCancel = () => {
    setModalIs(false)
  }

  const editHandler = (e) => {
    e.preventDefault()
    setModalLoader(true)
    const data =  {id, modalEventName, modalEventDate}
    if(modalEventName.length && modalEventDate !==null){
      postAPI(`/event/updateEvent`, data).then((res)=>{
        if(res.status === 200){
          getData()
          setModalLoader(false)
          setShowModal(false)
        }
      })
    }else{
      setModalLoader(false)
      if(modalEventName === ''){
        setEditModalNameErr({msg:'Required'})
      }
      if(modalEventDate === null){
        setEditModalDateErr({msg:'Required'})
      }

    }
    
  }

  const deleteOpenModal = (id) => {
    setModalIs(true)
    setId(id)
  }

  const deleteHandler = () => {
  setDeleteLoader(true)
  const {data} = eventData
  const deleteData = data?.find((el)=>el.id === id)
  deleteAPI(`/event/deleteEvent`, deleteData).then((res)=>{
    if(res.status === 200){
      setDeleteLoader(false)
      setModalIs(false)
      getData()
    }
  })
  }

  const openModalHandler = (id) => {
    setShowModal(true)
    const {data} = eventData 
    const editData = data?.find((el) => el.id === id)
    const date = new Date(editData?.date_event)
    setModalEventName(editData.event_name)
    setModalEventDate(date)
    setId(id)

  }



  const getData = () => {
    getAPI(`/event/getEvent`).then((res) => {
      if(res.status === 200){
        setEventData(res.data)
      }
    })
  }

  const dateHandler = (date) => {
    setFightLoader(true)
      setFightDate(date)
      getAPI(`/event/getFightByDate?date=${(date)}`).then((res)=>{
        if(res.status === 200){
          if(res.data.fightData.length > 0){
            setFighterData(res.data.fightData)
            setFightLoader(false)
          }else {
            setFighterData('')
            setFightLoader(false)
          }
         
        }
      })
  }

  const checkboxHandler = (e,item, index) => {
    const {checked} = e.target
    if(checked){
      setFighterData([ ...(fighterData.slice(0,index)),  {...item, customEventId:id}, ...(fighterData.slice(index+1, fighterData?.length))])
    }else {
      setFighterData([ ...(fighterData.slice(0,index)),  {...item, customEventId:null}, ...(fighterData.slice(index+1, fighterData?.length))])
    }
  }

 const addFightHandler = (e) => {
    e.preventDefault()
    setLoader(true)
    postAPI('/event/addFightsData',{ fighterData}).then((res)=>{
      if(res.status === 200){
        setLoader(false)
        getData()
        
      }else{
        setLoader(false)
      }
    })
 }
  useEffect(() => {
    getData()
  },[])

  return (
    <>
      <NavBar />
      <div className="main-content">
        <Header text={"Events List"} />
        <div className="container-fluid">

          <form >
          <div>
          {duplicateMsg && <div className = 'text-center'><p className="text-danger">{duplicateMsg.msg}</p></div>}
          </div>
            <div className="row my-4">
             
              <div className="col-lg-3">
                <label>Event Name:</label>
                <div >
                  <input
                    type="text"
                    className="form-control"
                    value={eventName}
                    onChange = {(e)=>{setEventName(e.target.value);setNameErr(''); setDuplicateMsg('') }}
                  />
                </div>
                <div>
                {nameErr && (<p className="text-danger">{nameErr.msg}</p>)}
                </div>
              </div>
              <div className="col-lg-3 ">
                <label>Event Date:</label>
                <div>
                  <DatePicker
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                  selected={eventDate} 
                  onChange = {(date) =>{setEventDate(date);setDateErr("")}} 
                  />
                </div>
                <div>
                {dateErr && (<p className="text-danger">{dateErr.msg}</p>)}
                </div>
              </div>
              <div className="col-sm-2">
                <button className="btn btn-primary addEventbtn btnsave" type = 'submit' onClick = {submitHandler}>
                  {loader ? (
                    <div className="spinner-border spinner-border-sm" role="status" >
                      {" "}
                      <span className="sr-only">Loading...</span>{" "}
                    </div>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>

              <div className="col-sm-2">
                <button className="btn btn-primary addEventbtn btnsave" type = 'submit' onClick = {getSechdule} disabled = {scheduleLoader}>
                  {scheduleLoader ? (
                    <div className="spinner-border spinner-border-sm" role="status" >
                      {" "}
                      <span className="sr-only">Loading...</span>{" "}
                    </div>
                  ) : (
                    "Get Schedule"
                  )}
                </button>
              </div>
            </div>
          </form>

<div className="table-responsive">

          <table className="table table-hover" id="myTable">
            <thead>
              <tr >
                <th>#</th>
                <th scope="col">Event Name</th>
                <th scope="col">Event Date</th>
                <th className='text-center' scope="col" >Edit</th>
                <th scope = 'col'>Delete</th>
              </tr>
            </thead>
            <tbody>
            {
              eventData
              ?
              (eventData?.data.map((ele, index) => (
                <>
                 <tr key = {index}>
                  <td>{index+1}</td>
                  <td>{ele?.event_name}</td>
                  <td>{moment(ele?.date_event).format("MM/DD/YYYY")}</td>
                  <td className='text-center' onClick = {()=>openModalHandler(ele?.id)}>
                    <i role="button" className='bi bi-pencil-fill'></i>
                  </td>
                  <td onClick = {() => deleteOpenModal(ele?.id)}><i role = 'button' class="bi bi-archive-fill"></i></td>
                </tr>
                </>
               
              ))) 
              :
              ""
            }
            </tbody>
				</table>
</div>

        <Modal isOpen={showModal} style={customStyles} onRequestClose={handleModalClose}  ariaHideApp={false}>
     
        <div className="" role="document text-center">
           <div className="w-100 text-center mb-4">
              <h2 className="modal-title" id="exampleModalLabel"> Edit Events Details</h2>
            </div>

            <form>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Event Name</label>
                  <input type="text" className="form-control" 
                  value = {modalEventName}  
                  onChange = {(e)=>{setModalEventName(e.target.value);setEditModalNameErr("")}} 
                  placeholder="Enter Event Name"/>
                  <div>
                {editModalNameErr && (<p className="text-danger">{editModalNameErr.msg}</p>)}
                </div>
                </div>
              <div className="form-group ">
               <label className='d-block' htmlFor="avatar">Event Date</label>
               <div>
                  <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={modalEventDate} 
                  onChange = {(date) =>{setModalEventDate(date);setEditModalDateErr("")}} 
                  />
                </div>
                <div>
                {editModalDateErr && (<p className="text-danger">{editModalDateErr.msg}</p>)}
                </div>
              </div>
              <hr/>
              <div>Select Fights for this Event</div>
              <DatePicker
              dateFormat = "MM/dd/yyyy"
              // minDate = {new Date()}
              selected = {fightDate}
              onChange = {dateHandler}
              />
             <table className="table table-hover" id="myTable">
                <thead>
                  <tr >
                    <th>#</th>
                    <th>Select</th>
                    <th scope="col">Fighter</th>
                  
                  </tr>
                </thead>
            
                <tbody>
                  <>
                  {
                    fightLoader ?
                    <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  : 
                  ""

                  }
                  
                {
                  fighterData && fighterData?.length
                  ?
                  (fighterData?.map((ele, index) => (
                    <>
                    <tr key = {index}>
                        <td>{index+1}</td>
                        <td>
                          <input
                          type = 'checkbox'
                          checked = {ele.customEventId === id}
                          onChange = {(e)=>checkboxHandler(e, ele, index)}
                          />
                        </td>
                        <td>
                          <div> {ele?.away_team}</div>
                          <div>{ele?.home_team}</div>
                        </td>
                      </tr>
                    </>
                  
                  ))) 
                  :
                  ""
                }
                </>
                </tbody>
			  	   </table>
              <div className="w-100 d-flex justify-content-center mt-5">
                  <button className="w-25 btn btn-primary mx-2" onClick = {addFightHandler}>
                  <span className="indicator-label fw-bolder">
                    {loader ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    ) : (
                      "Save Fights"
                    )}
                  </span>
                  </button>
                  <button onClick = {editHandler} disabled={loader} type="submit" className="w-25 btn btn-primary mx-2">
                    {modalLoader?
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>:
                    "Submit"
                    }
                    </button>
                  <button onClick={handleModalClose} type="submit" className="bg-dark border-0 w-25 btn btn-primary mx-2 ">Close</button>
                </div>
            </form>
        </div>

      </Modal>

      <Modal isOpen={ModalIs} style={customStyles}  onRequestClose={handleModalClose} contentLabel="Example Modal">
        <div>
          <h3 className="text-center p-5">Do you want to Remove this Event?</h3>
          <div className="text-center d-flex">
            <button
              type="button"
              id="kt_sign_in_submit"
              // className="btn btn-lg btn-orange w-100 mb-5 mx-2"
              className="btn btn-flex flex-center btn-danger btn-lg w-100 mb-5 mx-2"
              onClick={deleteHandler}
            >
              <span className="indicator-label fw-bolder">
                {deleteLoader ? (
                 <div class="spinner-border spinner-border-sm" role="status">
                 <span class="sr-only">Loading...</span>
               </div>
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5 mx-2"
              onClick={handleCancel}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>
        </div>
        
      </div>
      <ToastContainer/>
    </>
  );
};

export default CustomEvents;
