import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import exportFromJSON from "export-from-json";

import "react-datepicker/dist/react-datepicker.css";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI} from "../../utils/api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trimDate } from "../../utils/helper";
import Select from "react-select"

const Home = () => {
	const [usersDetailDate, setUsersDetailDate] = useState({from: new Date(), to:new Date()})
	const [loading, setLoading] = useState({userDetail: false, userLeaderboard: false})
	const [events, setEvents] = useState([])
	const [userLeaderBoardData, setUserLeaderBoardData] = useState([])
	const [userLeaderboardAnalysis, setUserLeaderboardAnalysis] = useState([])
	const exportType = exportFromJSON.types.csv
	const[loader, setLoader] = useState(false)

	const userDetailSubmit = () => {
		setLoading({...loading, userDetail: true})

		const from = usersDetailDate?.from.toJSON()
		const to = new Date(usersDetailDate?.to.getTime()+60 * 60 * 24 * 1000).toJSON()
		
		getAPI(`/user/getUsersTable?from=${from}&to=${to}`)
		.then(res => {
			if(res?.data?.data?.length){
				exportFromJSON({ data: res.data.data, fileName: "User Detail", exportType })
				toast.success('File Downloaded', { position: "top-right", autoClose: 3000 })
			} else {	
				toast.error("No Users found", { position: "top-right", autoClose: 3000 })
			}
			setLoading({...loading, userDetail: false})
		}).catch(err => {
			toast.error("Something went wrong", { position: "top-right", autoClose: 3000 }) 
			setLoading({...loading, userDetail: false})
		})
	}
	
	const userLeaderboardSubmit = () => {
		setLoading({...loading, userLeaderboard: true})
		getAPI(`/leaderboardChart/getUserLeaderboard?eventsArray=${JSON.stringify(userLeaderBoardData)}`)
		.then(res => {
			if(res?.data?.data?.length){
				exportFromJSON({ data: res.data.data, fileName: "User Leaderboard", exportType })
				toast.success('File Downloaded', { position: "top-right", autoClose: 3000 })
			} else {	
				toast.error("No Users found", { position: "top-right", autoClose: 3000 })
			}
			setLoading({...loading, userLeaderboard: false})
		}).catch(err => {
			toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
			setLoading({...loading, userLeaderboard: false})
		})
	}

	const handleUserDetailDate = (date, property) => {
		setUsersDetailDate({
			...usersDetailDate,
			[property]: date
		})
	}

	const handleUserLeaderboardDate = (events)=>{
 		setUserLeaderBoardData(events?.map(el=> ({event_name:el.event_name, event_date:el.event_date})))
	}

	
	const userLeaderboardAnalysisSubmit = () => {
		setLoader(true)
		getAPI(`/user/getAllUsersEventsData`).then(res => {
			if(res?.data?.status === 1){
				exportFromJSON({ data: res?.data?.data, fileName: "Users Analysis Data", exportType })
				toast.success('File Downloaded', { position: "top-right", autoClose: 3000 })
				setLoader(false)
			} else {	
				toast.error("No Data Found", { position: "top-right", autoClose: 3000 })
				setLoader(false)
			}
		}).catch(err => {
			toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
			setLoader(false)
		})
	}

	const getData = async()=>{
		getAPI('/leaderboardChart/getEventswithAllFightsCompleted').then((res)=>{
			if(res.status === 200){
				const {data} = res?.data
				data.reverse()
				if(data && data.length ){
					setEvents(data.map( el =>(
						{value:el.event_name, label:el.event_name, event_name:el.event_name, event_date:trimDate(el.date_event)}
					)))
				}
			}
		})
	}

	useEffect(()=>{
		getData()
	},[])	

	return (<div>
		<NavBar />
		<div className="main-content">
			<Header text={"Reports"} />
			<div className="container-fluid">
				{/* Users Detail */}
				<div className="row mx-1 mb-2"><h3>User's Detail</h3></div>
				<div className="row mb-6">
						<div className="col-md-3">
							<span>
								From:
								<DatePicker selected={usersDetailDate?.from} onChange={(date) => handleUserDetailDate(date, "from")} disabled={loading?.userDetail} maxDate={usersDetailDate?.to} /> 
							</span>
						</div>
					<div className="col-md-3">
						<span>
							To:
							<DatePicker selected={usersDetailDate?.to} 
							onChange={
								(date) => {	
									handleUserDetailDate(date, "to")
								}
							} 
							disabled={loading?.userDetail} 
							maxDate={new Date()} />
						</span>
					</div>
					<div className="col-md-3 d-flex align-items-end">
						<button onClick={userDetailSubmit} className="mr-2 my-2 btn btn-primary" disabled={loading?.userDetail} >
							{loading?.userDetail ? "Please wait ..." : "Generate report"}
						</button>
					</div>
				</div>
				<hr />

				{/* Users leaderboard */}
				   <div classe="row mx-1 mb-2"><h3>User's leaderboards</h3></div>
					<div className ="d-flex">
						<div className = "col-sm-12 col-md-6">
							<Select
								options = {events}
								onChange = {handleUserLeaderboardDate}
								isMulti
								className="basic-multi-select"
								classNamePrefix="select"
								isSearchable = {true}
							/>
						</div>
						<div className="col-md-3 d-flex align-items-end">
							<button onClick={userLeaderboardSubmit} className="mr-2 my-2 btn btn-primary" disabled={loading?.userLeaderboard} >
								{loading?.userLeaderboard ? "Please wait ..." : "Generate report"}
							</button>
						</div>
					</div>
					<hr />

					{/* Users Detailed Analysis */}
					<div classe="row mx-1 mb-2"><h3>User's Detailed Analysis</h3></div>
					
						<div className="col-md-3 d-flex align-items-end">
							<button onClick={userLeaderboardAnalysisSubmit} className="mr-2 my-2 btn btn-primary" disabled={loader} >
								{loader ? "Please wait ..." : "Generate report"}
							</button>
						</div>
					
					<hr />

			</div>
			</div>
			<ToastContainer />
		</div>
	);
};

export default Home;
