import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header';
import NavBar from '../../Navbar/NavBar';
import { getAPI, postAPI } from '../../utils/api'
import { List } from "react-movable";
import Modal from "react-modal";
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Blank from "../../Assets/img/avatars/blank.png"
import FormData from 'form-data'
import Axios from 'axios';
import { Baseurl, ImageUrl } from '../../utils/BaseUrl';

const Fighters = () => {
  const [fightersData, setFightersData] = useState([]);
  const [showModal, setShowModal]=useState(false);
  const [selectedFighter, setSelectedFighter]=useState();
  const [loader, setLoader]=useState(false);
  const [fighterLoader, setFighterLoader]=useState(false);

  toast.configure();
  
  

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      borderColor: "neutral10",
      "&:hover": {
        borderColor: "neutral10 !important",
      },
    }),
    overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: "500px",
      width: "100%",
    }
  };

  const getData = () => {
    getAPI(`/fighter/getAllFighters`).then(data => {setFightersData(data?.data?.fighters)})
}
  useEffect(() => {
    getData()
  }, [])

  const handleModalOpen=(email,id)=>{
    setShowModal(true);
  }

  const handleModalClose=()=>{
    setLoader(false)
    setShowModal(false);
    setSelectedFighter({})
  }

  const handleChange=(name, value)=>{
     setSelectedFighter({
       ...selectedFighter,
          [name]:value
      })
  }

  const handleSubmit=(e)=>{
     e.preventDefault()
     if(selectedFighter.url && selectedFighter.fighterPic){
        setLoader(true)
        const data = new FormData()
        data.append("file", selectedFighter.fighterPic);
        Axios.post(Baseurl +`/fighter/update?fighterId=${selectedFighter.fighterId}&url=${selectedFighter.url}`, data, {
          headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        })
         .then(res=>{
           setLoader(false)
           if( res?.data?.status === 1){
            toast.success("Fighter data updated successfully.",{autoClose: 2000})
            handleModalClose()
            getData()
          }else{
            toast.error("Something went wrong.",{autoClose: 2000});
          }
        })
     }else{
      toast.error("Incomplete details.",{autoClose: 2000});
     }
  }

  const fetchFighter = () => {
    setFighterLoader(true)
    postAPI('/fighter/sync').then(res => {
      if(res.data.status){
        setTimeout(()=>{toast.success("Fighter's data updated.",{autoClose: 2000}); getData(); setFighterLoader(false)}, 10000)
      }
    })
  }
  
  return (
    <>
      <NavBar />

      <div className="main-content">
        <Header text={"Fighters List"} />

        <div className="container-fluid">
        <div className="row">
				  <div className="col-sm-9"></div>
          <div className="col-sm-3 mb-2">
            <button className="btn btn-primary w-100 shadow " disabled={fighterLoader} onClick={fetchFighter}>
            { fighterLoader ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Update Fighter"} 
            </button>
          </div>
			  </div>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <span
                  style={{ minWidth: 400 }}
                  className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3"
                >
                </span>
              </div>
              <table className="table table-hover" id="myTable">
                <thead>
                  <tr >
                    <th>#</th>
                    <th className='text-center' scope="col">Fighter_Pic</th>
                    <th scope="col">Fighter_Name</th>
                    <th scope="col">Fighter_Url</th>
                    <th className='text-center' scope="col">Edit_Fighter</th>
                  </tr>
                </thead>
                <List
                  values={fightersData}
                  renderList={({children}) => (
                    <tbody>{children}</tbody>
                  )}
                  renderItem={({value}) => (

                    <tr>
                      <td>{fightersData.indexOf(value) + 1}</td>
                      <td className='text-center'>
                       <div>
                       <img className="rounded-circle fighter-img" src={value?.fighterPic ? ImageUrl + value?.fighterPic : Blank} alt="" />
                       </div>
                      </td>
                      <td><div>{value?.fighterName}</div></td>
                      <td>{value?.url ? value?.url : "Url not found."}</td>
                      <td className='text-center' onClick={() => {setSelectedFighter(value); handleModalOpen();}}>
                        <i role="button" className='bi bi-pencil-fill'></i>
                      </td>
                    </tr>
                  )}
                />
              </table>
            </div>
          </div>
        </div>
      </div>

      {/*Edit Email Model*/}

      <Modal isOpen={showModal} style={customStyles} onRequestClose={handleModalClose}  ariaHideApp={false}>
     
        <div className="" role="document text-center">
           <div className="w-100 text-center mb-4">
              <h2 className="modal-title" id="exampleModalLabel">{showModal?.edit} Edit Fighter Details</h2>
            </div>

            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Fighter Url</label>
                  <input type="text" class="form-control" id="url" value={selectedFighter?.url} onChange={(e)=>handleChange("url", e.target.value)} placeholder="Enter Url"/>
              </div>
              <div class="form-group ">
               <label className='d-block' for="avatar">Fighter Pic</label>
               <input type="file" id="avatar" name="files" accept="image/png, image/jpeg" onChange={(e)=>handleChange("fighterPic", e.target.files[0])}/>
              </div>
              <div className="w-100 d-flex justify-content-center mt-5">
              <button disabled={loader} onClick={handleSubmit} type="submit" class="w-25 btn btn-primary mx-2">
                {loader?
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>:
                "Submit"
                }
                </button>
              <button onClick={handleModalClose} type="submit" class="bg-dark border-0 w-25 btn btn-primary mx-2 ">Close</button>
              </div>
            </form>
        </div>

      </Modal>
    </>
  )
}

export default Fighters