import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Page/Home/Home";
import Login from "./Page/Login/Login";
import Table from "./Page/Table/Table"
import RssFeeds from "./Page/RssFeeds/RssFeeds"
import Users from "./Page/Table/Users"
import Fighters from "./Page/Table/Fighters";
import UsersBets from "./Page/User'sBets/User'sBets";
import UpdateUsersBets from "./Page/UpdateUser'sBets/updateUser'sBets";
import CustomEvents from "./Page/Table/CustomEvents"
import EventCard from "./Page/Table/EventCard";


const AuthorizationContext = React.createContext();

const App = () => {
  
  const token = localStorage.getItem("token");

  const PrivateRoute = () => {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/reports" element={<Home/>} />
          <Route path="/users" element={<Users/>} />
          <Route path="/fighters" element={<Fighters/>} />
          <Route path="/events" element={<Table/>} />
          <Route path="/user's-bets" element={<UsersBets/>} />
          <Route path="/update-user's-bets" element={<UpdateUsersBets/>} />
          <Route path="/rss-feeds" element={<RssFeeds/>} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path = "/custom-events" element = {<CustomEvents />} />
          <Route path = "/event-card" element = {<EventCard />} />
          </Routes>
      </Router>
    );
  };

  const PublicRoute = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  };

  return (
    <>
      <AuthorizationContext.Provider value={token}>
        {token ? <PrivateRoute /> : <PublicRoute />}
      </AuthorizationContext.Provider>
    </>
  );
};

export default App;
