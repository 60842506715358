import React, { useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI, postAPI } from "../../utils/api";
import Select from "react-select";
import {getResultNew2} from "../../utils/helper"
import Modal from "react-modal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
const EventCard = (props) => {
  const [results, setResult] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [earlyPrelims, setEarlyPrelims] = useState({fightName:'', fightTime:''});
  const [prelims, setPrelims] = useState({fightName:'', fightTime:''});
  const [mainCard, setMainCard] = useState({fightName:'', fightTime:''});
  const [loader, setLoader] = useState(false)
  const [loaderforFighter,setLoaderforFighter] = useState(false)
  const [cardType, setCardType] = useState([{fightName:'', fightTime:''}])
 
  const getData = async () => {
    getAPI(`/schedule/getAllScheduleWith2FutureEvents?year=2022`).then((res) => {
      let latestEvent = res?.data?.data[0];
      latestEvent = {
        value: latestEvent?.date_event,
        label: latestEvent?.event_name,
        ...latestEvent,
      };

      setEvents(res?.data?.data);
      
      
      setSelectedOption(latestEvent);
    });
  }

  useEffect(() => {
    getData()
  }, []);

  const getDataIs = async() => {
    setLoaderforFighter(false)
    if (selectedOption) {
      const { date_event, event_name } = selectedOption;
      getAPI(`/schedule/getScheduleDetailsAdmin?date_event=${date_event}&event_name=${event_name}`)
      .then((res) => {
        const data = res?.data?.data;

        const dataSortedByIndex = data.sort( (a, b) => a?.adminIndex - b?.adminIndex )
        
        setResult(dataSortedByIndex);
        
        setLoaderforFighter(true)

      });
    }
  }

   const setMainCartAndPrilimsDate = (res)=>{
   
    // filter form maincard date
    const mainCard = res.filter((data)=>{return data.event_card=="Main Card"})

    // //filter for prilims
    const prilimsDate = res.filter((data)=>{return data.event_card=="Prelims"})

    // filter for early prilims
    const earlyPrelimsdate = res.filter((data)=>{return data.event_card=="Early Prelims"})

    if(mainCard.length&&mainCard[0]?.eventCardTime){
      let date = new Date(mainCard[0]?.eventCardTime)
      setMainCard({...mainCard,fightTime:date,fightName:'Main Card'})
    } else{
      setMainCard({fightTime:''})
    }
    //  set set prilims
    
    if(prilimsDate.length&&prilimsDate[0]?.eventCardTime){
      let date = new Date(prilimsDate[0]?.eventCardTime)
      setPrelims({...prelims,fightTime:date,fightName:"Prelims"})
    } else {
      setPrelims({fightTime:''})
    }

    //  // set set early prilims
    if(earlyPrelimsdate.length&&earlyPrelimsdate[0]?.eventCardTime){
      let date = new Date(earlyPrelimsdate[0]?.eventCardTime)
      setEarlyPrelims({...earlyPrelims,fightTime:date,fightName:"Early Prelims"})
    } else {
      setEarlyPrelims({fightTime:''})
    }
   }

   useEffect(()=>{
    if(results.length) {
      setMainCartAndPrilimsDate(results)
    }
  },[results])

  useEffect(() => {
   getDataIs()
  }, [selectedOption]);

   const customStyles = {
    control: (base, state) => ({ ...base, boxShadow: "none", borderColor: "neutral10", "&:hover": { borderColor: "neutral10 !important", },}),
    overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
    option: (styles, { isFocused, isSelected }) => ({ ...styles, background: isFocused ? "#ceced0" : isSelected ? "#fff" : undefined, color: "gray",}),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', maxWidth: "500px", width: "100%",}
  };

  const options = events?.map(obj => ({ value: obj?.date_event, label: obj?.event_name, ...obj })) 
  

 const options4 = [
    {value:0, label:'Early Prelims'},
    {value:1, label:'Prelims'},
    {value:2, label:'Main Card'}
  ]

const eventcardHandler = (name, value, el) => {
  const id  = el.event_id
  postAPI(`/schedule/updateEventCard`, {value, id}).then((res) => {
      if(res.status === 200){
        getDataIs()
        toast.success('Updated', { position: "top-right", autoClose: 3000 })
      }
  })
}

const earlyPrelimsHandler = (val, event_card) => {
setEarlyPrelims({...earlyPrelims,fightTime:val,fightName:event_card})
}

const prelimsHandler = ( val, event_card) => {
setPrelims({...prelims, fightTime:val,fightName:event_card})
}

const mainCardHandler = (val, event_card) => { 
setMainCard({...mainCard, fightTime:val, fightName:event_card})
}




const submitHandler = () => {
  setLoader(true)
    let data = []
    data.push(earlyPrelims, prelims, mainCard, selectedOption)
    postAPI(`/schedule/createFightTime`,data).then((res)=>{
      if(res.status === 200){
        getDataIs()
        toast.success('Updated', { position: "top-right", autoClose: 3000 })
        setLoader(false)
        setEarlyPrelims({fightTime:''})
        setPrelims({fightTime:''})
        setMainCard({fightTime:''})
      }
    })

}

  return (
    <div>
      <NavBar />
      <div className="main-content">
        <Header text={"Fight Events"} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
                  <div className = "text-danger" >NOTE: Before setting time for cards please set event card type in the dropdown first.</div>
                  <div  className = "text-danger" style = {{paddingBottom:'10px'}}>FOR UPDATE: Change card type in dropdown and update time according to card type.</div>
                {/* <div className = 'd-lg-flex' style = {{justifyContent:'space-around', paddingBottom:'20px'}}> */}
                
                <div className = 'row'> 
                  <div className="col-lg-3 col-12 col-md-6 mr-2">
                  <span className="mr-4 head-sm">Main Card</span>
                    <span>
                     <DateTimePicker onChange={(e)=>{mainCardHandler(e,'Main Card')}} value={mainCard?.fightTime} />
                    </span>
                  </div>
                  <div className="col-lg-3 col-12 col-md-6 mr-2">
                  <span className="mr-4 head-sm">Prelims</span>
                    <span>
                      <DateTimePicker onChange={(e) =>prelimsHandler(e,"Prelims")} value={prelims.fightTime} />
                    </span>
                 </div>
                 <div className="col-lg-3 col-12 col-md-6">
                 <span className="mr-4 head-sm">Early Prelims</span>
                    <span>
                     <DateTimePicker onChange={(e)=>earlyPrelimsHandler(e,'Early Prelims')} value = {earlyPrelims.fightTime} />
                    </span>
                 </div>
                 <div className="col-lg-2 col-12 savebtn ml-lg-4 mt-3">
                  <button className="btn btn-primary btnsave" type = 'submit' onClick = {submitHandler}>
                    {loader ? (
                      <div className="spinner-border spinner-border-sm" role="status" >
                        {" "}
                        <span className="sr-only">Loading...</span>{" "}
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                 </div>
                </div>
               
              <div className="mt-2">
                <span
                  style={{ minWidth: 400 }}
                  className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3"
                >
                  
                  <Select
                    isSearchable={false}
                    options={options}
                    onChange={(data) =>{ setSelectedOption(data);}}
                    value={selectedOption}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </span>
              </div>
              <div className="table-responsive">

              <table className="table table-hover" id="myTable">
                <thead>
                  <tr >
                    <th scope="col">#</th>
                    <th scope="col">Fighters</th>
                    <th scope="col">Moneyline</th>
                    <th scope="col">Status</th>
                    <th scope="col">Result</th>
                    <th scope = "col">Event Card</th>
                    <th scope = "col">Event Date</th>
                    <th scope = "col">Event Time</th>
                  </tr>
                </thead>
                <tbody>
                    {
                    loaderforFighter ? results.map((ele, index) => {
                    return <tr key = {index}>
                        <td>{index+1}</td>
                        <td>
                        <div>{ele?.away_team}</div>
                        <div>{ele?.home_team}</div>
                      </td>
                      <td>
                        <div>{ele?.moneyline_away}</div>
                        <div>{ele?.moneyline_home}</div>
                      </td>
                      <td>{ele?.event_status}</td>
                      <td>
                        <div><span className={getResultNew2(ele?.result?.winner_away)==="WIN"?`text-success`:getResultNew2(ele?.result?.winner_away)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(ele?.result?.winner_away)}</span></div>
                        <div><span className={getResultNew2(ele?.result?.winner_home)==="WIN"?`text-success`:getResultNew2(ele?.result?.winner_home)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(ele?.result?.winner_home)}</span></div>
                      </td>
                      <td>
                          <Select
                          options = {options4}
                          value = {{value:ele?.event_card, label:ele?.event_card}}
                          onChange = {(data)=>eventcardHandler("event_card",data.label, ele)}
                          />
                      </td>
                      <td>{ele?.eventCardTime ? moment(ele?.eventCardTime).format('L'):"-"}</td>
                      <td>{ele?.eventCardTime ? moment(ele?.eventCardTime).format('HH:mm:ss'):"-"}</td>
                   </tr>
                    }):
                    <></>
                    }
                </tbody>
              </table>
              </div>
              {loaderforFighter?<></>:
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>

  );
};

export default EventCard;
