import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI, postAPI } from "../../utils/api";
import { sortingArray } from "../../utils/helper";

const RssFeeds = (props) => {

	const [rssList, setRssList] = useState([])
	const [loader, setLoader] = useState(false)
	const [sortByName, setSortByName] = useState("id")
	const [sortingIs, setSortingIs] = useState(false)
	const [sortingType, setSortingType] = useState("number")
	const [selectedRSS, setSeletedRSS] = useState({})
	const [showModal, setModal] = useState({edit: false, delete:false})
	const [modalLoader, setModalLoader] = useState(false)

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			maxWidth: "500px",
			width: "100%",
		},
	  };

	useEffect(() => {
		getAllRssFeedsgetList()
	}, [])

	const getAllRssFeedsgetList = async () => {
		setLoader(true)	
		getAPI(`/rss/getAllRssFeeds`).then(res => {
			if(res?.data?.status){ 
				setRssList(res?.data?.rssList.reverse())
				setLoader(false)
			}
		})
	}

	const handlePublish = (id, flag) => {
		postAPI(`/rss/publish`, {id, flag}).then(res=> {
			if(res?.data?.status){
				setRssList(res?.data?.rssList.reverse())
			}
		})
	}

	const handleSorting = (sortByName, sortingType) => {
		// sortingArray(rssList, setRssList, sortByName, setSortByName, sortingIs, setSortingIs, sortingType)
	}

	const handleUpdate = (type) => {
		setModalLoader(true)
		postAPI('/rss/update', {selectedRSS, type}).then(res => {
			if(res?.data?.status){
				setRssList(res?.data?.rssList.reverse())
				setModal({})
				setModalLoader(false)
			}
		})
	}

	const handleDelete= () => {
		setModalLoader(true)
		postAPI('/rss/delete', {selectedRSS}).then(res => {
			if(res?.data?.status){
				setRssList(res?.data?.rssList.reverse())
				setModal({})
				setModalLoader(false)
			}
		})
	}

	const handleAdd= () => {
		setModalLoader(true)
		postAPI('/rss/add', {selectedRSS}).then(res => {
			if(res?.data?.status){
				setRssList(res?.data?.rssList.reverse())
				setModal({})
				setSeletedRSS({})
				setModalLoader(false)
			}
		})
	}

	const handleChange = e => setSeletedRSS({ ...selectedRSS, [e.target.name] : e.target.value})

	const handleModalClose = () => {
		setModal({})
		setSeletedRSS({})
	}

	return (
		<div>
			<NavBar />
			<div className="main-content">
				<Header text={"RSS Feeds"} />
				<div className="container-fluid">
					<div className="row">
						<div className="w-100 mb-4 d-flex justify-content-end">
							<button onClick={() => {setModal({edit: "Add"}); setSeletedRSS({});}} className="mr-2 btn btn-primary">Add RSS</button>
						</div>
						<div className="col-lg-12">
							<table className="table table-hover" id="myTable">
								<thead>
									<tr>
										<th className="position-relative cursorPointer" onClick={() => handleSorting("id", "number")}>
											id
											{/* {sortByName==="id" && (sortingIs ? <i className="position-absolute bi bi-chevron-down"></i> : <i className="position-absolute bi bi-chevron-up"></i>)} */}
										</th>
										<th className="position-relative cursorPointer" onClick={() => handleSorting("title", "string")}>
											Title
											{/* {sortByName==="title" && (sortingIs ? <i className="position-absolute bi bi-chevron-down"></i> : <i className="position-absolute bi bi-chevron-up"></i>)} */}
										</th>
										<th className="position-relative cursorPointer" onClick={() => handleSorting("author", "string")}>
											Author
											{/* {sortByName==="author" && (sortingIs ? <i className="position-absolute bi bi-chevron-down"></i> : <i className="position-absolute bi bi-chevron-up"></i>)} */}
										</th>
										<th className="position-relative cursorPointer" onClick={() => handleSorting("published", "date")}>
											Published On
											{/* {sortByName==="published" && (sortingIs ? <i className="position-absolute bi bi-chevron-down"></i> : <i className="position-absolute bi bi-chevron-up"></i>)} */}
										</th>
										<th>Link</th>
										<th className="position-relative cursorPointer" onClick={() => handleSorting("isActive", "string")}>
											Status
											{/* {sortByName==="isActive" && (sortingIs ? <i className="position-absolute bi bi-chevron-down"></i> : <i className="position-absolute bi bi-chevron-up"></i>)} */}
										</th>
										<th></th>
									</tr>
								</thead>
                                <tbody>
									{
										loader
										?
										<tr className="spinner-border" />
										:
										rssList.map((r, index) => (
											<tr key={index}>
												<td>{r?.id}</td>
												<td>{r?.title}</td>
												<td>{r?.author}</td>
												<td>{(r?.published)?.split("T")[0]}</td>
												<td><a href={r?.link} target="_blank"><i className="bi bi-box-arrow-up-right"></i></a></td>
												<td>
													{
														r?.isActive 
														? 
														<span className="badge badge-success cursorPointer" onClick={() => handlePublish(r.id, false)}>Published</span> 
														: 
														<span className="badge badge-secondary cursorPointer" onClick={() => handlePublish(r.id, true)}>Not Published</span>
														
													}
												</td>
												<td>
													<div onClick={() => setSeletedRSS(r)}>
														<i className="bi bi-three-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
														<div class="dropdown-menu">
															<span data-toggle="modal" data-target="#editModal" class="dropdown-item" onClick={() => setModal({edit:"Edit"})}>Edit</span>
															<span data-toggle="modal" data-target="#deleteModal" class="dropdown-item" onClick={() => setModal({delete:true})}>Delete</span>
														</div>
													</div>
												</td>
											</tr>
										))
									}
                                </tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			{/* edit modal */}
			<Modal isOpen={showModal?.edit} style={customStyles} onRequestClose={handleModalClose}>
				<div className="" role="document">
					<div className="">
						<div className="">
							<h3 className="modal-title" id="exampleModalLabel">{showModal?.edit} RSS Feeds</h3>
						</div>
						<div className="">
							<form>
								<div className="form-group">
									<label for="recipient-name" className="col-form-label">Title:</label>
									<input type="text" name="title" className="form-control" value={selectedRSS?.title} onChange={handleChange} />
								</div>
								<div className="form-group">
									<label for="recipient-name" className="col-form-label">Link:</label>
									<input type="text" name="link" className="form-control" value={selectedRSS?.link} onChange={handleChange} />
								</div>
								<div className="form-group">
									<label for="recipient-name" className="col-form-label">Author:</label>
									<input type="text" name="author" className="form-control" value={selectedRSS?.author} onChange={handleChange} />
								</div>
								<div className="form-group">
									<label for="recipient-name" className="col-form-label">Cover Photo:</label>
									<input type="text" name="content" className="form-control" value={selectedRSS?.content} onChange={handleChange} />
								</div>
							</form>
						</div>
						<div className="d-flex justify-content-center">
							<button type="button" className="mx-3 btn btn-secondary" onClick={handleModalClose}>Close</button>
							<button type="button" disabled={modalLoader} className="mx-3 btn btn-primary" onClick={showModal?.edit === "Edit"?() => handleUpdate(showModal?.edit): handleAdd}>
								{modalLoader ? <div className="spinner-border spinner-border-sm" role="status" ></div>: "Save"}
							</button>
						</div>
					</div>
				</div>
			</Modal>

			{/* delete modal */}
			<Modal isOpen={showModal?.delete} style={customStyles} onRequestClose={handleModalClose}>
				<div>
					<div >
						<h5 className="modal-title mb-4" id="exampleModalLabel">Delete RSS Feed</h5>
					</div>
					<form>
						<div className="form-group">
							<label for="recipient-name" className="col-form-label">{selectedRSS?.title}</label>
						</div>
					</form>
					<div className="d-flex justify-content-center">
						<button type="button" className="mx-3 btn btn-secondary" onClick={handleModalClose}>Close</button>
						<button type="button" disabled={modalLoader} className="mx-3 btn btn-primary" onClick={handleDelete}>
							{modalLoader ? <div className="spinner-border spinner-border-sm" role="status" ></div>: "Delete"}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default RssFeeds;