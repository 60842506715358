import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI, postAPI } from "../../utils/api";
import Select from "react-select";
import {getResultNew2} from "../../utils/helper"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-modal";

const UsersBets = (props) => {
  
	const [events, setEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState();
	const [users, setUsers] =  useState([])
	const [selectedUser, setSelectedUser] =  useState()
	
	const [selectedUserEvent, setSelectedUserEvent] = useState()
	const [selectedBetType, setSelectedBetType] = useState({label: "Bets", value:"bets"})
	const [betType,setbetType] = useState(1);
	const [loader, setLoader]= useState({search: false, leaderboard: false, edit: false});

	const [editFight,setEditFight] = useState({show: false, data: undefined})
	//Delete Risk amount
	const [deleteFight,setDeleteFight] = useState({show:false, data: undefined})

	//Edit risk amount state 
	const [editRisk,setEditRisk] = useState("")
	
	const [updateRisk,setUpdateRisk] = useState({show: false, data: undefined});

	// Create bet
	const [createFight,setCreateFight] = useState({show: false, data: undefined});
	const [selectFighter,setSelectFighter] = useState(null);
	const [createRiskAmount,setCreateRiskAmount] = useState(null);
	const [winAmount,setWinAmount] = useState(null);
	const [moneyLine,setMoneyLine] = useState(null);
	
	const [createParlayFight,setCreateParlayFight] = useState({show: false, data: undefined});
	const [parlayFilteredData,setParlayFilteredData] = useState();
	const [parlayRiskAmount,setParlayRiskAmount] = useState(null);
	const [createParlayRiskAmount,setCreateParlayRiskAmount] = useState(null)
	
	const getData = async () => {
		getAPI(`/schedule/getAllSchedule?year=2022`).then((res) => {
			setEvents(res?.data?.data);
		});
		getAPI(`/user/getUsersTableAll`).then((res) => {
			setUsers(res.data?.data);
		});
	}

	// console.log(editFight)
	
	const customStyless = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			maxWidth: "500px",
			width: "100%",
		},
	};
	const searchEvent = () => {
		// console.log(selectedBetType?.value,"selectedBetType?.valueselectedBetType?.value");
		
		if(selectedUser && selectedEvent){
			setLoader({...loader, search: true})
			const ongoingEvent = events.findIndex(e => (e.event_name === selectedEvent.event_name) && (e.date_event === selectedEvent.date_event)) === 0 ? true : false
			if(selectedBetType?.value === "bets"){
				
				getAPI(`/user/getUser${ongoingEvent?"":"Past"}BetEventByDate?date=${selectedEvent?.date_event}&eventName=${selectedEvent?.event_name}&userId=${selectedUser?.id}`)
				.then(res => {
					setSelectedUserEvent(res?.data?.data?.sort((a,b) => a.adminIndex - b.adminIndex));
					setbetType(1);
					setLoader({...loader, search: false})
				})
			} else {
				getAPI(`/user/getUser${ongoingEvent?"":"Past"}ParlayEventByDate?date=${selectedEvent?.date_event}&eventName=${selectedEvent?.event_name}&userId=${selectedUser?.id}`)
				.then(res => {
					// console.log("ressssssssssssss",res);
					setbetType(2)
					setSelectedUserEvent(res?.data?.data?.sort((a,b) => a.adminIndex - b.adminIndex));
					// console.log(selectedUserEvent,"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
				const newData = res?.data?.data?.sort((a,b) => a.adminIndex - b.adminIndex).filter((elem)=>{
						return (elem.events.length > 0)
					})
			
					setParlayFilteredData(newData);
					// console.log(newData[0]?.risks[0]?.risk,"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
					if(newData[0]?.risks[0]?.risk){
						// console.log(newData[0]?.risks[0]?.risk,"newData[0]?.risks[0]?.risknewData[0]?.risks[0]?.risk");
						setParlayRiskAmount(newData[0]?.risks[0]?.risk);
						setCreateParlayRiskAmount(newData[0]?.risks[0]?.risk)
					}else{
						setParlayRiskAmount();
						setCreateParlayRiskAmount()
					}
					
					setLoader({...loader, search: false})
				})
			}
			
		} else {
			toast.error('Select user and event both.', { position: "top-right", autoClose: 3000 })
		}
		
	}
	// console.log(parlayRiskAmount,"parlayRiskAmountparlayRiskAmountparlayRiskAmount");
	// if(selectedUserEvent){
	// 	const newData = selectedUserEvent.filter((elem)=>{
	// 		return (elem.events.length != 0)
	// 	})

	// 	setParlayFilteredData(newData);
		                                                                                                                                                                                                                     
	// 	console.log(newData,"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
	// 	// console.log(parlayFilteredData,"parlayFilteredDataparlayFilteredDataparlayFilteredData");
	// }
	const generateLeaderboard = () => {
		setLoader({ ...loader, leaderboard:true })
		getAPI('/leaderboardChart/triggerUserLeaderboard').then(res => {
		  toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
		  setLoader({ ...loader, leaderboard:false })
		})
	}

	const updateEditFight = (pickedTeam,moneyLine,risk,userEventId)=> {
		if(selectedBetType?.value == 'bets'){

			const win =	(risk && moneyLine)
				? moneyLine > 0
					? (risk * moneyLine) / 100
					: (-risk * 100) / moneyLine
				: 0;
			setEditFight({
				show: true, 
				data: {
					...(editFight?.data), 
					events: [{
						...(editFight?.data?.events[0]), 
						pickedTeam,
						moneyLine,
						win
					}]
				}
			})
		} else {
			parlayFilteredData?.forEach((e)=>{
				e?.events?.forEach(ele => {
					if(ele?.id == userEventId){
						ele.moneyLine = moneyLine;
						ele.pickedTeam = pickedTeam
					}
				});
			})

			const updatedEvent = []
			let newEvent2 = {};
			let win = 0
			parlayFilteredData?.forEach((elem)=>{
		
				newEvent2 = {};
				newEvent2.scheduleId = elem.id;
				newEvent2.pickedTeam = elem.events[0].pickedTeam
				newEvent2.riskId = elem.events[0].riskId
				newEvent2.moneyLine = elem.events[0].moneyLine
				newEvent2.problity=calculateProbility(elem.events[0].moneyLine,elem.events[0].pickedTeam )
				
				updatedEvent.push(newEvent2) 
			})
			win = getParlayWin(updatedEvent,parlayFilteredData[0]?.risks?.[0]?.risk)
			setEditFight({
				show: true, 
				data: {
					...(editFight?.data), 
					events: [{
						...(editFight?.data?.events[0]), 
						pickedTeam,
						moneyLine,
						win
					}]
				}
			})
		}
	}
	// console.log(currentWin)
	const handleSubmit = userEvent => {
		postAPI('/event/updatePickedTeam', userEvent).then(res => {
			if(res?.data?.status){
				toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
				setEditFight({ show: false, data: undefined })
				searchEvent()
			} else {
				toast.error(res?.data?.message, { position: "top-right", autoClose: 3000 })
			}
		})
	}
//To delete the bet
const handleDeleteSubmit = (userEvent) =>{

	const updatedEvent = []
	let newEvent2 = {};
	let win = 0
	if(betType == 2) {
	parlayFilteredData.map((elem,i)=>{

		newEvent2 = {};
		newEvent2.scheduleId = elem.id;
		newEvent2.pickedTeam = elem.events[0].pickedTeam
		newEvent2.riskId = elem.events[0].riskId
		newEvent2.moneyLine = elem.events[0].moneyLine
		newEvent2.problity=calculateProbility(elem.events[0].moneyLine,elem.events[0].pickedTeam )
		newEvent2.filterId = elem.events[0].id
		newEvent2.risk =elem.risks[0].risk
		updatedEvent.push(newEvent2) 
	})
	const result = updatedEvent.filter(elem=>elem.filterId != userEvent.id)
	if(result.length){
	win = getParlayWin(result,parlayFilteredData[0].risks[0].risk)
	}
	
	

	postAPI('/event/updateRisk',{risk:parlayFilteredData[0].risks[0].risk,win:win,riskId:userEvent.riskId}).then(res => {
		if(res?.data?.status){
			// toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
			setEditRisk("")
			setUpdateRisk({ show: false, data: undefined })
			searchEvent()
		} else {
			toast.error(res?.data?.message, { position: "top-right", autoClose: 3000 })
		}
	})
}

	postAPI("/event/deletePickedTeam",{...userEvent}).then(res => {
		if(res?.data?.status){
			toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
			setDeleteFight({ show: false, data: undefined })
			searchEvent()
		} else {
			toast.error(res?.data?.message, { position: "top-right", autoClose: 3000 })
		}
	})
}

// Update user Bet amount
//  const getCurrentWin = (risk, moneyLine,riskId) => {
// 	// console.log(risk, moneyLine,riskId,"sadfaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa111111111111111111111");
	

// 	const win =
// 	  (risk && moneyLine)
// 		? moneyLine > 0
// 		  ? (risk * moneyLine) / 100
// 		  : (-risk * 100) / moneyLine
// 		: 0;
// 	const currentWin = Number(win?.toFixed(2));
// 	// console.log(currentWin,"currentWincurrentWincurrentWincurrentWincurrentWin");
// 	if(editRisk){
// 		console.log(risk,currentWin,riskId);
// 		postAPI('/event/updateRisk',{risk,win:currentWin,riskId}).then(res => {
// 			if(res?.data?.status){

// 				// toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
// 				setEditRisk("")
// 				setUpdateRisk({ show: false, data: undefined })
// 				searchEvent()
// 			} else {
// 				toast.error(res?.data?.message, { position: "top-right", autoClose: 3000 })
// 			}
// 		})
// 	}
// 	else{
// 		toast.error('Please enter the amount', { position: "top-right", autoClose: 3000 })
// 	}
//   };



const getCurrentWin = (risk, moneyLine,riskId,betType) => {
	
	const updatedEvent = []
	let newEvent2 = {};
	let win=0 
	if(betType==2){
		
	parlayFilteredData.map((elem)=>{
		
		newEvent2 = {};
		newEvent2.scheduleId = elem.id;
		newEvent2.pickedTeam = elem.events[0].pickedTeam
		newEvent2.riskId = elem.events[0].riskId
		newEvent2.moneyLine = elem.events[0].moneyLine
		newEvent2.problity=calculateProbility(elem.events[0].moneyLine,elem.events[0].pickedTeam )
		
		updatedEvent.push(newEvent2) 
		win = getParlayWin(updatedEvent,risk)
	})
	
	} else {
		win =
		(risk && moneyLine)
			? moneyLine > 0
			? (risk * moneyLine) / 100
			: (-risk * 100) / moneyLine
			: 0;
	 }
	
	
	
	if(editRisk){
		postAPI('/event/updateRisk',{risk,win:win,riskId}).then(res => {
			if(res?.data?.status){

				// toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
				setEditRisk("")
				setUpdateRisk({ show: false, data: undefined })
				searchEvent()
			} else {
				toast.error(res?.data?.message, { position: "top-right", autoClose: 3000 })
			}
		})
	}
	else{
		toast.error('Please enter the amount', { position: "top-right", autoClose: 3000 })
	}
  };


// Create Bet

const changeBet = (e,moneyLine)=>{
	if(!selectFighter){
		// console.log("we are in the if");
		toast.error('Please select the fighter', { position: "top-right", autoClose: 3000 });
		return ;
	}
	const win =	(e.target.value && moneyLine)
	  ? moneyLine > 0
		? (e.target.value * moneyLine) / 100
		: (-e.target.value * 100) / moneyLine
	  : 0;
	//   console.log(e.target.value,"sssssssssssssssssssssssss");
	setMoneyLine(moneyLine)
	setCreateRiskAmount(e.target.value)
  const currentWin = Number(win?.toFixed(2));
  setWinAmount(currentWin)
  return
}
const changeParlay = (e,moneyLine)=>{
	if(!selectFighter){
		// console.log("we are in the if");
		toast.error('Please select the fighter', { position: "top-right", autoClose: 3000 });
		return ;
	}
	const win =	(e.target.value && moneyLine)
	  ? moneyLine > 0
		? (e.target.value * moneyLine) / 100
		: (-e.target.value * 100) / moneyLine
	  : 0;
	//   console.log(e.target.value,"sssssssssssssssssssssssss");
	setMoneyLine(moneyLine)
	setCreateParlayRiskAmount(e.target.value)
  const currentWin = Number(win?.toFixed(2));
  setWinAmount(currentWin)
  return
}
const createBet = (obj)=>{

	if(Number(createRiskAmount) <= 0){

		toast.error('Amount should be more then 0', { position: "top-right", autoClose: 3000 });
		return
	}
	if(!winAmount || !selectFighter ||  !createRiskAmount){
		// console.log("we are in the if");
		toast.error('Please select all the parameters', { position: "top-right", autoClose: 3000 });
		return
	}
	
	
	
	 let data = {userId : selectedUser?.id,
	 status : "2",
	 betType : (selectedBetType?.value === "bets")?"1":"2",
	 events : [
		{
			scheduleId:obj.id,
			risk:createRiskAmount,
			pickedTeam:selectFighter,
			win:winAmount,
			moneyLine:moneyLine,
			status:"2",
			customEventId:obj.customEventId,
			away_team: obj.away_team,
			home_team: obj.home_team
			
			
		}
	]}
	postAPI("/event/createEvent",data).then(res => {
		toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
				
		setCreateFight({show: false, data: undefined});
		// console.log(res);
		searchEvent()
	})
	// console.log(data,"dataaaaaaaaaaaaaaaaaaa");
} 

const getParlayWin = (parlayevents, risk ) =>{
	const hasProblity = parlayevents?.filter((el ) => el.problity !== "")
	var productIs  = []
	 hasProblity?.forEach((item ) =>{
	  const a = item?.problity  / 100
	  productIs.push({problity : a})
	})
   const multiplyOfProduct = productIs?.reduce((accumulator, current) =>
		Number(accumulator) * Number(current?.problity),
	  1
	);
	
	const finalProduct = multiplyOfProduct && (multiplyOfProduct*100);
  
	const y = ((100 - finalProduct) * 100)/ finalProduct;
  
	const finalWin = (risk && y && productIs?.length > 0) ?  (risk * y)/100 : 0;
	const currentWin = finalWin?.toFixed(2)
   return currentWin
  }

const calculateProbility = (moneyLine, pickedTeam) =>{
	const p = moneyLine ? (moneyLine > 0) ? (100 / (moneyLine + 100)) : 1 + (100/((moneyLine) - 100)) : 0;
	const prob = p*100
	return prob.toFixed(2) 
}



const createParlayBet = (fight)=>{

	const newEvent = [];
	let newEvent2 = {};
	let totalWin = 0 ;
	if(!selectFighter){
	
		toast.error('Please select the fighter', { position: "top-right", autoClose: 3000 });
		return ;
	}
	parlayFilteredData.map((elem)=>{
		newEvent2 = {};
		newEvent2.scheduleId = elem.id;
		newEvent2.pickedTeam = elem.events[0].pickedTeam
		newEvent2.riskId = elem.events[0].riskId
		newEvent2.moneyLine = elem.events[0].moneyLine
		newEvent2.problity=calculateProbility(elem.events[0].moneyLine,elem.events[0].pickedTeam )
		newEvent2.customEventId = elem.customEventId

		newEvent.push(newEvent2)
	  
	})
	// console.log(fight.customEventId ,"fight")
	const newParlayBet = {
		scheduleId:fight.id,
		pickedTeam:selectFighter,
		riskId:'',
		moneyLine:selectFighter == 1 ? fight.moneyline_home : fight.moneyline_away,
		problity:calculateProbility(selectFighter == 1 ? fight.moneyline_home : fight.moneyline_away,selectFighter ),
		customEventId:fight.customEventId,
		away_team:fight.away_team,
		home_team:fight.home_team
	}
	newEvent.push(newParlayBet)
	const totalwin = getParlayWin(newEvent,createParlayRiskAmount)
	const {id} = selectedUser
	
	postAPI(`/event/createParlayFight`, {newEvent, totalwin, id, createRiskAmount:createParlayRiskAmount}).then((res) => {
		
		setParlayRiskAmount();
		setCreateParlayRiskAmount()
		if(res.status === 200){
			toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
			setCreateParlayFight({show: false, data: undefined});
			searchEvent()
		}
	})

}
	useEffect(() => {
		getData()
	}, [])

	const customStyles = {
		control: (base) => ({ ...base, boxShadow: "none", borderColor: "neutral10", "&:hover": { borderColor: "neutral10 !important", }, }),
		overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
		option: (styles, { isFocused, isSelected }) => ({ ...styles, background: isFocused ? "#ceced0" : isSelected ? "#fff" : undefined, color: "gray", }),
		menuPortal: (base) => ({ ...base, zIndex: 9999 }),
		content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', maxWidth: "50%", width: "100%", }
	}

	const eventOptions = events?.map(obj => ({ value: obj?.date_event, label: obj?.event_name, ...obj }))

	const usersOptions = users?.map(obj => ({ value: obj?.screenName, label: obj?.email, ...obj }))

	const betTypeOption = [{label: "Bets", value:"bets"},{label: "Parlays", value:"parlays"}]

	return (
		<div>
		<NavBar />
		<div className="main-content">
			<Header text={"User's Bets Data"} />
			<div className="container-fluid">
			<div className="row">
				<div className="col-sm-2">
					<span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3" >
						<Select
							isSearchable={true}
							options={usersOptions}
							onChange={(data) => setSelectedUser(data)}
							value={selectedUser}
							menuPortalTarget={document.body}
							styles={customStyles}
							placeholder="Select or type Users"
						/>
					</span>
				</div>

				<div className="col-sm-2">
					<span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3" >
						<Select
							isSearchable={true}
							options={eventOptions}
							onChange={(data) => setSelectedEvent(data)}
							value={selectedEvent}
							menuPortalTarget={document.body}
							styles={customStyles}
							placeholder="Select or type events"
						/>
					</span>
				</div>

				<div className="col-sm-2">
					<span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3" >
						<Select
							isSearchable={false}
							options={betTypeOption}
							onChange={(data) => {setSelectedBetType(data)}}
							value={selectedBetType}
							menuPortalTarget={document.body}
							styles={customStyles}
							placeholder="Bet type"
						/>
					</span>
				</div>

				<div className="col-sm-2">
					<button className="btn btn-primary w-100" disabled={loader?.search} onClick={searchEvent}>
					{ loader?.search ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Search"} 
					</button>
				</div>

				<div className="col-sm-4">
					<button className="btn btn-primary w-100 shadow" disabled={loader?.leaderboard} onClick={generateLeaderboard} >
						{ loader?.leaderboard ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Regenenerate Leaderboard"}
					</button>
				</div>

			</div>
				<table className="table table-hover" id="myTable">
					<thead>
					<tr >
						<th>#</th>
						<th scope="col">Fighters</th>
						<th scope="col">Moneyline</th>
						<th scope="col">Status</th>
						<th scope="col">Result</th>
						<th scope="col">User</th>
						<th scope="col">Risk</th>
						<th scope="col">Picked Team</th>
						
						<th scope="col">Update team</th>
						<th>Create team</th>
					</tr>
					</thead>
					<tbody>
						{
							selectedUserEvent && selectedUserEvent.length 
							? 
							selectedUserEvent.map((fight, index) => (
								<tr key={index} className={fight?.updatedByAdmin?`my-1 bg-light border rounded shadow`:""}>
									<td>{index + 1}</td>
									<td>
										<div>{fight?.away_team}</div>
										<div>{fight?.home_team}</div>
									</td>
									<td>
										<div>{fight?.moneyline_away}</div>
										<div>{fight?.moneyline_home}</div>
									</td>
									<td>{fight?.event_status}</td>
									<td>
										<div><span className={getResultNew2(fight?.result?.winner_away)==="WIN"?`text-success`:getResultNew2(fight?.result?.winner_away)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(fight?.result?.winner_away)}</span></div>
										<div><span className={getResultNew2(fight?.result?.winner_home)==="WIN"?`text-success`:getResultNew2(fight?.result?.winner_home)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(fight?.result?.winner_home)}</span></div>
									</td>
									<td>
										<a target="_blank" href={`https://mmabetclub.com/player/${selectedUser?.screenName}`}>{selectedUser?.screenName}</a>
									</td>
									{
										selectedBetType?.value === "bets"
										?
										<td>
											{/* {console.log(fight?.events[0],"fight?.events[0]fight?.events[0]fight?.events[0]fight?.events[0]")} */}
											<div>{fight?.events[0]?.pickedTeam === 2 && !isNaN(fight?.events[0].risk) ? "$"+fight?.events[0].risk : "-" }</div>
											<div>{fight?.events[0]?.pickedTeam === 1 && !isNaN(fight?.events[0].risk) ? "$"+fight?.events[0].risk : "-" }</div>
										</td>
										:
										<td>
											<div>{ fight?.events[0]?.pickedTeam === 2 && !isNaN(fight?.risks?.[0]?.risk) ? "$"+fight?.risks?.[0]?.risk : "-" }</div>
											<div>{ fight?.events[0]?.pickedTeam === 1 && !isNaN(fight?.risks?.[0]?.risk) ? "$"+fight?.risks?.[0]?.risk : "-" }</div>
										</td>
									}
									<td>
										{
											fight?.events[0]
											?
											<>
												<div className={fight?.events[0]?.pickedTeam === 2 ? "" : "text-muted"}>{fight?.away_team}</div>
												<div className={fight?.events[0]?.pickedTeam === 1 ? "" : "text-muted"}>{fight?.home_team}</div>
											</>
											:
											"-"
										}										
									</td>
									 
									
									<td>
										{
											fight?.events[0]
											?
									<div class="dropdown">
										<a  type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<a className='text-muted' href='#' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
												<i className='bi bi-three-dots-vertical'></i>
											</a>
										</a>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a class="dropdown-item" href="#">{
													fight?.events[0]
													?
													<div onClick={()=>setEditFight({show: true, data: fight})} className="dropdown">
														<button className=" border-0 "  style={{ background: "none" }}>Change Fighter</button>
													</div>
													:
													""
												}
											</a>
											<a class="dropdown-item" href="#">{
													fight?.events[0]
													?
													<div onClick={()=> setUpdateRisk({show:true,data:fight})} className="dropdown">
														<button className=" border-0 " style={{ background: "none"  }}>Update Risk</button>
													</div>
													:
													""
												}
											</a>
											<a class="dropdown-item" href="#">{
												fight?.events[0]
												?
												<div onClick={()=>setDeleteFight({show: true, data: fight})} className="dropdown">
													
													<button className=" border-0 "  style={{ background: "none" }}>Delete Fight</button>
												</div>
												:
												""
											}</a>
										</div>
									</div>:""}
									</td>
									{(selectedBetType?.value === "bets")?<td>
										{
											fight?.events[0]
											?
											"-"
											:
											<div onClick={()=> setCreateFight({show:true,data:fight})} className="dropdown">
												<button className="bi bi-pencil-fill border-0 " style={{ background: "none",color:"red" }}></button>
											</div>
										}
									</td>:<td>{fight?.events[0]
											?
											"-"
											:<div onClick={()=>{ setCreateParlayFight({show:true,data:fight});}} className="dropdown">
											<button className="bi bi-pencil-fill border-0 " style={{ background: "none",color:"red" }}></button>
										</div>}</td>}
									
								</tr>
							))
							:
							""
						}
						
					</tbody>
				</table>
			</div>
		</div>
		{/* ---------------------Create bet Risk-------------- */}
		<Modal isOpen={createFight?.show} style={customStyless}   >
		<div className="row d-flex align-items-center justify-content-center">
				<div onClick={() => setSelectFighter(2)} className={`m-2 my-3 col-6 btn ${selectFighter === 2 ? "btn-success shadow-sm" : "btn-light shadow-sm"}`}>
					{createFight?.data?.away_team}
				</div>
				<div onClick={() => setSelectFighter(1)} className={`m-2 my-3 col-6 btn ${selectFighter === 1 ? "btn-success shadow-sm" : "btn-light shadow-sm"}`}>
					{createFight?.data?.home_team}
				</div>
				
				<input 
				type="number" 
				maxLength={5} 
				value={createRiskAmount}  
				disabled={!selectFighter}
				placeholder="enter amount" onChange={(e)=>{return (e.target.value<= 999999 ?(selectFighter==2)?changeBet(e,createFight?.data?.moneyline_away):changeBet(e,createFight?.data?.moneyline_home):"")}}								 
						className="m-2 my-3 col-6  border" />
						<div className="m-2 my-3 col-6 btn border">Win amount : {winAmount}</div>

				<div className="w-100 d-flex justify-content-center">
						
					<button disabled={loader?.edit} onClick={()=>createBet(createFight.data)} type="submit" className="btn btn-primary m-2  btn btn-primary m-2 mt-4 w-25 border-0 ">
						{ loader?.edit ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Submit"}
					</button>
					<button onClick={() => {setCreateFight({show: false, data: undefined});setCreateRiskAmount();setSelectFighter();setWinAmount()}} type="submit" className="btn btn-primary  m-2 mt-4 w-25 bg-dark border-0">Close</button>
				</div>
			</div>
		</Modal>
		{/* ------------------Create parlay bet-------------- */}
		<Modal isOpen={createParlayFight?.show} style={customStyless}  onRequestClose={() => setCreateParlayFight()}>
		<div className="row d-flex align-items-center justify-content-center">
				<div onClick={() => setSelectFighter(2)} className={`m-2 my-3 col-6 btn ${selectFighter === 2 ? "btn-success shadow-sm" : "btn-light shadow-sm"}`}>
					{createParlayFight?.data?.away_team}
				</div>
				<div onClick={() => setSelectFighter(1)} className={`m-2 my-3 col-6 btn ${selectFighter === 1 ? "btn-success shadow-sm" : "btn-light shadow-sm"}`}> 
					{createParlayFight?.data?.home_team}
				</div>
				
				<input 
				type="number" 
				maxLength={5} 
				value={parlayRiskAmount?parlayRiskAmount:null}  
				disabled={!selectFighter || parlayRiskAmount }
				placeholder="enter amount" onChange={(e)=>{return (e.target.value<= 999999 ?(selectFighter==2)?changeParlay(e,createParlayFight?.data?.moneyline_away):changeParlay(e,createParlayFight?.data?.moneyline_home)
													:"")}}  
						className="m-2 my-3 col-6 p-2 text-center" />
						 

				<div className="w-100 d-flex justify-content-center">

					<button disabled={loader?.edit} onClick={()=>createParlayBet(createParlayFight?.data)} type="submit" className="btn btn-primary m-2  btn btn-primary m-2 mt-4 w-25 border-0 ">
						{ loader?.edit ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Submit"}
					</button>
					<button onClick={() => {setCreateParlayFight({show: false, data: undefined});setCreateRiskAmount();setSelectFighter();setWinAmount()}} type="submit" className="btn btn-primary  m-2 mt-4 w-25 bg-dark border-0">Close</button>
				</div>
			</div>
		</Modal>
		{/* -----_ Edit fight modal -------------- */}
		<Modal isOpen={editFight?.show} style={customStyless} onRequestClose={() => setEditFight()}  ariaHideApp={false}>
		{/* {console.log(editFight.data.events[0],"editFighteditFighteditFighteditFight")} */}
			<div className="" role="document text-center">
			
			<div className="row">
				<div className="w-100 text-center">
					<h2 className="modal-title" id="exampleModalLabel">Edit Picked team</h2>
					<p className="text-danger mt-3">Note: For past events, you need to Regenerate Leaderboard.</p>
				</div>
			</div>

			<div className="row d-flex align-items-center justify-content-center">
				<div onClick={() => updateEditFight(2,editFight?.data?.moneyline_away,editFight?.data?.events[0]?.risk,editFight?.data?.events[0]?.id)} className={`m-2 my-3 col-6 btn ${editFight?.data?.events[0]?.pickedTeam === 2 ? "btn-success shadow-sm" : "btn-light shadow-sm"}`}>
					{editFight?.data?.away_team}
				</div>
				<div onClick={() => updateEditFight(1,editFight?.data?.moneyline_home,editFight?.data?.events[0]?.risk,editFight?.data?.events[0]?.id)} className={`m-2 my-3 col-6 btn ${editFight?.data?.events[0]?.pickedTeam === 1 ? "btn-success shadow-sm" : "btn-light shadow-sm"}`}>
					{editFight?.data?.home_team}
				</div>
				
			</div>
			
			<div className="w-100 d-flex justify-content-center">
				<button disabled={loader?.edit} onClick={() => {let event = editFight?.data?.events[0]; handleSubmit(event); editRisk&&getCurrentWin(editRisk,event.moneyLine,event.riskId,betType)}} type="submit" className="btn btn-primary m-2  btn btn-primary m-2 mt-4 w-25 border-0 ">
					{ loader?.edit ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Submit"}
				</button>
				<button onClick={() => setEditFight({show: false, data: undefined})} type="submit" className="btn btn-primary  m-2 mt-4 w-25 bg-dark border-0">Close</button>
			</div>
			</div>
		</Modal>
		{/* *********************update risk amount */}
		<Modal isOpen={updateRisk.show} style={customStyless}  onRequestClose={() => setUpdateRisk({show: false, data: undefined})}>
		<div className="text-center">
		<h2 className="modal-title mr-4 border-1"  id="exampleModalLabel">Update Bet Amount</h2>
					<input type="number" maxLength={5} value={editRisk}  placeholder="enter amount" onChange={(e)=>{return e.target.value<= 999999?setEditRisk(e.target.value):"";}}  className="m-2 my-3 col-6  text-center" />
				</div>
				<div className="w-100 d-flex justify-content-center">
				<button disabled={loader?.edit} onClick={() => {let event = updateRisk?.data?.events[0];getCurrentWin(editRisk,event.moneyLine,event.riskId,betType)}} type="submit" className="btn btn-primary m-2  btn btn-primary m-2 mt-4 w-25 border-0 ">
					{ loader?.edit ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Submit"}
				</button>
				<button onClick={() => setUpdateRisk({show: false, data: undefined})} type="submit" className="btn btn-primary  m-2 mt-4 w-25 bg-dark border-0">Close</button>
			</div>
		</Modal>
		{/* ------Delete fight modal------------- */}
		<Modal isOpen={deleteFight?.show} style={customStyless} onRequestClose={() => setDeleteFight()} >
		<div className="" role="document text-center">
		<div className="text-center">
					<h2 className="modal-title mr-4" id="exampleModalLabel">Are you sure you want to delete the bet</h2>
					
		</div>
				<div className="w-100 d-flex justify-content-center">
				<button disabled={loader?.edit} onClick={() => handleDeleteSubmit(deleteFight?.data?.events[0])} type="submit" className="btn btn-primary m-2  btn btn-primary m-2 mt-4 w-25 border-0 ">
					{ loader?.edit ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Submit"}
				</button>
				<button onClick={() => setDeleteFight({show: false, data: undefined})} type="submit" className="btn btn-primary  m-2 mt-4 w-25 bg-dark border-0">Close</button>
			</div>
		</div>
		</Modal>

		<ToastContainer />
	</div>
  )
}

export default UsersBets
