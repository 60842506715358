import React from 'react';

const Header = (props) => {
    return (
        <div className="col-12 col-xl-12">
            <div className="card text-right p-3">
                <h1 className='text-left'>{props?.text || "MMA BetClub"}</h1>
                {/* <div class="input-group  d-flex justify-content-right">
                  <div class="form-outline">
                    <input type="search" id="form1" class="form-control" placeholder='Search...' />
                  </div>
                  <button type="button" class="mx-1 btn btn-primary">
                    <i class="fe fe-search"></i>
                  </button>
                </div> */}
            </div>
        </div>
        
    )
}
export default Header