export const sortingArray = (array, setArray, sortByName, setSortByName, sortingIs, setSortingIs, type) => {
    setSortingIs(!sortingIs)
    setSortByName(sortByName)
    const newArray = [...array]
    if(type ==="number"){
        if(sortingIs){
            newArray.sort((a,b) => a[sortByName] - b[sortByName])
        } else {
            newArray.sort((a,b) => b[sortByName] - a[sortByName])
        }
    } else if(type === "string"){
        if(sortingIs){
            newArray.sort((a,b) => (a[sortByName] > b[sortByName]) ? -1 : ((b[sortByName] > a[sortByName]) ? 1 : 0))
        } else {
            newArray.sort((a,b) => (a[sortByName] > b[sortByName]) ? 1 : ((b[sortByName] > a[sortByName]) ? -1 : 0))
        }
    } else if(type === "date"){
        if(sortingIs){
            newArray.sort((a,b)=>new Date(b[sortByName]).getTime() - new Date(a[sortByName]).getTime())
        } else {
            newArray.sort((a,b)=>new Date(a[sortByName]).getTime() - new Date(b[sortByName]).getTime())
        } 
    }
    setArray(newArray)
    return array
}


export const getResultNew2 = (result) => {
const resultIs =
    result === 0
    ? "TBD"
    : result === 1
    ? "WIN"
    : result == 2
    ? "LOSE"
    : result == 3
    ? "DRAW"
    : result == 4
    ? "CANCELED"
    : "";
return resultIs;
};

export const trimDate = date => new Date(date)?.toISOString()?.split("T")[0]