import React, { useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI, postAPI } from "../../utils/api";
import Select from "react-select";
import {getResultNew2} from "../../utils/helper"
import Modal from "react-modal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Table = (props) => {
    
  const [results, setResult] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [showModal, setModal] = useState({ edit: false, delete: false })
  const [loader, setLoader]= useState(false);
  const [buttonLoader, setButtonLoader] = useState({ result: false, leaderboard: false, alertEmail:false, MoneyLineLoader:false})
  const [tableLoader,setTableLoader] = useState(false)

  const getData = async () => {
    getAPI(`/schedule/getAllScheduleWith2FutureEvents?year=2022`).then((res) => {
      let latestEvent = res?.data?.data[0];
      latestEvent = {
        value: latestEvent?.date_event,
        label: latestEvent?.event_name,
        ...latestEvent,
      };
      setEvents(res?.data?.data);
      setSelectedOption(latestEvent);
    });
  }
  useEffect(() => {
    getData()
  }, []);

  const getDataIs = async() => {
    setTableLoader(false);
    if (selectedOption) {
      const { date_event, event_name } = selectedOption;
      getAPI(`/schedule/getScheduleDetailsAdmin?date_event=${date_event}&event_name=${event_name}`)
      .then((res) => {
        const data = res?.data?.data;
        const dataSortedByIndex = data.sort( (a, b) => a?.adminIndex - b?.adminIndex )
        setResult(dataSortedByIndex);
        setTableLoader(true)

      });
    }
  }
  useEffect(() => {
    getDataIs();
  }, [selectedOption]);

  const handleSubmit=() => {
    if(selectedEvent){
      setLoader(true);
      postAPI("/schedule/updateSchedule", selectedEvent).then(res=> {
        if(res?.data?.status){
          toast.success("Event updated", { position: "top-right", autoClose: 3000 })
          handleModalClose()
          
          getDataIs();
        } else {
          toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
        }
      })
    }
 }


  const handleOrderChanged = ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(results, oldIndex, newIndex);
    setResult(newArray);
    postAPI(`/schedule/updateAdminIndex`, { events: newArray });
  };

  const customStyles = {
    control: (base, state) => ({ ...base, boxShadow: "none", borderColor: "neutral10", "&:hover": { borderColor: "neutral10 !important", },}),
    overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
    option: (styles, { isFocused, isSelected }) => ({ ...styles, background: isFocused ? "#ceced0" : isSelected ? "#fff" : undefined, color: "gray",}),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', maxWidth: "500px", width: "65%",}
  };

  const options = events?.map(obj => ({ value: obj?.date_event, label: obj?.event_name, ...obj })) 

  const handleModalClose = () => {
    setLoader(false)
    setModal({})
    setSelectedEvent({})
  }
  const options2 = [
    { value: "STATUS_SCHEDULED", label: 'STATUS_SCHEDULED' },
    { value: 'STATUS_FINAL', label: 'STATUS_FINAL' },
    { value: 'STATUS_CANCELED', label: 'STATUS_CANCELED' }
  ]

  const options3 = [
    { value: 0, label: 'TBD' },
    { value: 1, label: 'WIN' },
    { value: 2, label: 'LOSE' },
    { value: 3, label: 'DRAW' },
  ]

  const options4 = [
    {value:0, label:'Early Prelims'},
    {value:1, label:'Prelims'},
    {value:2, label:'Main Card'}
  ]

  const handleSelectChange = (name ,value) => {
    const oppValue = value === 1 ? 2 : (value === 2 ? 1 : "")
    const oppValueName = name === "winner_home" ? "winner_away" : (name === "winner_away" ? "winner_home" : "")

    if(value===0 || value===3){
      setSelectedEvent({ ...selectedEvent, result: { "winner_home": value, "winner_away": value } })
    } else if( value===1 || value === 2) {
      if(oppValue && oppValueName){
        setSelectedEvent({ ...selectedEvent, result: { [name]: value, [oppValueName]: oppValue } })
      }
    }
  }

  const handleChange = (name,value) => { setSelectedEvent({ ...selectedEvent, [name]: value }) }

  const alertEmail = () => {
    setButtonLoader({ ...buttonLoader, alertEmail:true })
    getAPI(`/rundown/fighterChangeEmail`).then(res => {
      toast.success('Email send successfully', { position: "top-right", autoClose: 3000 })
      setButtonLoader({ ...buttonLoader, alertEmail:false })
    })
  }
  const newFightaddEmail = (value)=>{
    setButtonLoader({ ...buttonLoader, alertEmail:true })
    postAPI('/schedule/sendNewFightEmail', value).then(res => {
      toast.success('Email send successfully', { position: "top-right", autoClose: 3000 })
      setButtonLoader({ ...buttonLoader, alertEmail:false })
    })
  }
  const updateMoneyLine = () => {
    setButtonLoader({ ...buttonLoader, MoneyLineLoader:true })
    getAPI('/rundown/getScheduleDetailsWithMoneyLine').then(res=>{
      console.log(res.data)
      if(res.data?.status) {
        setButtonLoader({ ...buttonLoader, MoneyLineLoader:false })
        getDataIs()
        toast.success("Schedules updated Successfully", { position: "top-right", autoClose: 3000 })
      }
      else {
        setButtonLoader({ ...buttonLoader, MoneyLineLoader:false })
        toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
      }
      setButtonLoader({ ...buttonLoader, MoneyLineLoader:false })
    }).catch(e=>{
      setButtonLoader({ ...buttonLoader, MoneyLineLoader:false })
      console.log(e)
    })
  }

  const cancelledEmail = (value) => {
    postAPI('/rundown/cancelledfightEmail',value).then((res) => {
      if(res.status===200){
        toast.success("Reset Successfully", { position: "top-right", autoClose: 3000 })
      }else{
        toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
      }
    })
  }

  const getResults = () => {
    setButtonLoader({ ...buttonLoader, result:true })
    getAPI(`/rundown/getResultDetailsAfterEvent`).then(res => {
      toast.success('Updated', { position: "top-right", autoClose: 3000 })
      setButtonLoader({ ...buttonLoader, result:false })
    })
  }

  const generateLeaderboard = () => {
    setButtonLoader({ ...buttonLoader, leaderboard:true })
    getAPI('/leaderboardChart/triggerUserLeaderboard').then(res => {
      toast.success(res?.data?.message, { position: "top-right", autoClose: 3000 })
      setButtonLoader({ ...buttonLoader, leaderboard:false })
    })
  }

  const resetHandler = (data) => {
    postAPI('/schedule/resetUpdatedByAdmin',data).then((res) => {
      if(res.status===200){
        toast.success("Reset Successfully", { position: "top-right", autoClose: 3000 })
        
        getDataIs();
      }else{
        toast.error("Something went wrong", { position: "top-right", autoClose: 3000 })
      }
    })
  }

  return (
    <div>
      <NavBar />
      <div className="main-content">
        <Header text={"Fight Events"} />
        <div className="container-fluid ">
          <div className="row d-flex justify-content-end">
            {/* <button className="btn btn-primary mx-3 mb-3" disabled={buttonLoader?.alertEmail} onClick={alertEmail}>
              { buttonLoader?.alertEmail ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"send alert email"} 
            </button> */}
             <button className="btn btn-primary mx-3 mb-3" disabled={buttonLoader?.MoneyLineLoader} onClick={updateMoneyLine}>
              { buttonLoader?.MoneyLineLoader ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Update Fights"} 
            </button>
            {/* <button className="btn btn-primary mx-3 mb-3" disabled={buttonLoader?.alertEmail} onClick={newFightaddEmail}>
              { buttonLoader?.alertEmail ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"New Fight Email"} 
            </button> */}
            <button className="btn btn-primary mx-3 mb-3" disabled={buttonLoader?.result} onClick={getResults}>
              { buttonLoader?.result ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Get Result"} 
            </button>
            <button className="btn btn-primary mx-3 mb-3" disabled={buttonLoader?.leaderboard} onClick={generateLeaderboard} >
              { buttonLoader?.leaderboard ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Generate Leaderboard"}
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div>
                <span
                  style={{ minWidth: 400 }}
                  className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3"
                >
                  <Select
                    isSearchable={false}
                    options={options}
                    onChange={(data) => setSelectedOption(data)}
                    value={selectedOption}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </span>
              </div>
              <table className="table table-hover" id="myTable">
                <thead>
                  {window.innerWidth > 790 ? <tr >
                    <th>#</th>
                    <th scope="col">Fighters</th>
                    <th scope="col">Moneyline</th>
                    <th scope="col">Current Status</th>
                    <th scope = "col">Card</th>
                    <th scope="col">Result</th>
                    <th scope='col'>Action</th>
                    <th scope='col'>Reset</th>
                    <th scope="col">Cancel Email</th>
                    <th scope="col">New Added Fight</th>
                    <th scope="col">New Status</th>
                  </tr> :
                  <tr >
                  <th>#</th>
                  <th scope="col">Fighters</th>
                  {/* <th scope="col">Moneyline</th> */}
                  <th scope="col">Current Status</th>
                  {/* <th scope = "col">Card</th> */}
                  <th scope="col">Result</th>
                  <th scope='col'>Action</th>
                  {/* <th scope='col'>Reset</th> */}
                </tr>
                  }
                </thead>
                {tableLoader?<>{window.innerWidth > 790 ? <List
                  values={results}
                  onChange={handleOrderChanged}
                  renderList={({ children, props }) => {
                    return(
                      <tbody {...props}>{children}</tbody>
                    )}}
                  renderItem={({ value, props }) =>{
                    return (
                    <tr className={value?.updatedByAdmin?`my-1 bg-light border rounded shadow`:""} {...props}>
                      <td>{results.indexOf(value) + 1}</td>
                      <td>
                        <div>{value?.away_team}</div>
                        <div>{value?.home_team}</div>
                      </td>
                      <td>
                        <div>{value?.moneyline_away}</div>
                        <div>{value?.moneyline_home}</div>
                      </td>
                      <td>{value?.result?.winner_away == 3 && value?.result?.winner_home ==3 ? 'STATUS_FINAL' : value?.event_status}</td>
                      <td>{value?.event_card}</td>
                      <td>
                        <div><span className={getResultNew2(value?.result?.winner_away)==="WIN"?`text-success`:getResultNew2(value?.result?.winner_away)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(value?.result?.winner_away)}</span></div>
                        <div><span className={getResultNew2(value?.result?.winner_home)==="WIN"?`text-success`:getResultNew2(value?.result?.winner_home)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(value?.result?.winner_home)}</span></div>
                      </td>
                      <td>
                        <div onClick={()=>setSelectedEvent(value)} className="dropdown">
                          <button  onClick={()=>setModal({ edit: true })} className="bi bi-pencil-fill border-0 " style={{ background: "none" }}></button>
                          </div>
                      </td>
                      <td><button className = "btn btn-primary" disabled = {value?.updatedByAdmin===false} onClick = {()=>resetHandler(value)}>Reset</button></td>
                      <td><button className = "btn btn-primary" disabled = {value?.event_status!="STATUS_CANCELED"} onClick = {()=>{cancelledEmail(value)}}>Email</button></td>
                      <td><button className = "btn btn-primary" disabled = {value?.isFightUpdated===false} onClick = {()=>{newFightaddEmail(value)}}>Email</button></td>
                      <td>{value?.result?.winner_away == 3 && value?.result?.winner_home ==3 ? 'STATUS_FINAL' : value?.new_status}</td>
                    </tr>
                  )}}
                />:<>
                  <tbody>
                 { results?.map((value,index)=>(<tr key={index} className={value?.updatedByAdmin?`my-1 bg-light border rounded shadow`:""} {...props}>
                      <td>{results.indexOf(value) + 1}</td>
                      <td>
                        <div>{value?.away_team}</div>
                        <div>{value?.home_team}</div>
                      </td>
                      {/* <td>
                        <div>{value?.moneyline_away}</div>
                        <div>{value?.moneyline_home}</div>
                      </td> */}
                      <td>{value?.result?.winner_away == 3 && value?.result?.winner_home ==3 ? 'STATUS_FINAL' : value?.event_status}</td>
                      {/* <td>{value?.event_card}</td> */}
                      <td>
                        <div><span className={getResultNew2(value?.result?.winner_away)==="WIN"?`text-success`:getResultNew2(value?.result?.winner_away)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(value?.result?.winner_away)}</span></div>
                        <div><span className={getResultNew2(value?.result?.winner_home)==="WIN"?`text-success`:getResultNew2(value?.result?.winner_home)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(value?.result?.winner_home)}</span></div>
                      </td>
                      <td>
                        <div onClick={()=>setSelectedEvent(value)} className="dropdown">
                          <button  onClick={()=>setModal({ edit: true })} className="bi bi-pencil-fill border-0 " style={{ background: "none" }}></button>
                          </div>
                      </td>
                      {/* <td><button className = "btn btn-primary" disabled = {value?.updatedByAdmin===false} onClick = {()=>resetHandler(value)}>Reset</button></td> */}
                    </tr>))
                  }
                  </tbody>
                </>}
                </>:<></>
                }
              </table>
              {tableLoader?<></>:
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>

      {/* -----_ Edit fight modal -------------- */}
      <Modal isOpen={showModal?.edit} style={customStyles} onRequestClose={handleModalClose}  ariaHideApp={false}>
     
        <div className="" role="document text-center">
         
          <div className="row">
            <div className="w-100 text-center mb-4">
          
              <h2 className="modal-title" id="exampleModalLabel">{showModal?.edit} Edit-Event</h2>
              <p className="text-danger mt-3">Note: Once updated, the data of this fight will not be updated automatically.</p>
            </div>
            <div className="col col-md-6 text-center d-flex flex-column">
              <h4>Away Team</h4>
              <input type="text" className="form-control m-2 " style={{ cursor: "not-allowed" }} defaultValue={selectedEvent?.away_team} disabled={true} placeholder="" />
              <input type="number" className="form-control m-2" placeholder="Moneyline" name="moneyline_away" defaultValue={selectedEvent?.moneyline_away} onChange={(e)=>handleChange("moneyline_away", e.target.value)} />
              <div className="m-2 w-100" >
                <Select
                  isSearchable={false}
                  name="winner_away"
                  options={options3}
                  onChange={(data)=>handleSelectChange("winner_away", data?.value)}
                  value={{ value: selectedEvent?.result?.winner_away,label: getResultNew2( selectedEvent?.result?.winner_away )}}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="col col-md-6 text-center d-flex flex-column">
              <h4>Home Team</h4>
              <input type="text" className="form-control m-2" style={{ cursor: "not-allowed" }} defaultValue={selectedEvent?.home_team} disabled={true} placeholder="Fighter's Name" />
              <input type="number" className="form-control m-2" placeholder="Moneyline" name="moneyline_home" defaultValue={selectedEvent?.moneyline_home} onChange={(e)=>handleChange("moneyline_home", e.target.value)}/>
              <div className="m-2  w-100">
                <Select
                 isSearchable={false}
                  options={options3}
                  onChange={(data)=>handleSelectChange("winner_home", data?.value)}
                  value={{value: selectedEvent?.result?.winner_home,label: getResultNew2( selectedEvent?.result?.winner_home )}}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                />
              </div>
            </div>


          </div>
          <div className="my-3 ml-2 w-100">
            <Select
              isSearchable={false}
              options={options2}
              onChange={(data) => handleChange("event_status", data?.value)}
              defaultValue={{ value: selectedEvent?.event_status, label: selectedEvent?.event_status }}
              menuPortalTarget={document.body}
              styles={customStyles}
            />
          </div>
          {/* <div  className="my-3 ml-2 w-100">
            <Select
            options = {options4}
            onChange = {(data)=>fightCard("event_card", data?.label)}
            defaultValue={{ value: selectedEvent?.event_card, label: selectedEvent?.event_card }} //! why
            styles = {customStyles}
          />
          </div> */}
          
          <div className="w-100 d-flex justify-content-center">
          <button disabled={loader} onClick={handleSubmit } type="submit" className="btn btn-primary m-2  btn btn-primary m-2 mt-4 w-25 border-0 ">
            { loader ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Submit"}
          </button>
          <button onClick={handleModalClose} type="submit" className="btn btn-primary  m-2 mt-4 w-25 bg-dark border-0">Close</button>
          </div>
        </div>
      </Modal>
      <ToastContainer/>
    </div>

  );
};

export default Table;
