import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header';
import NavBar from '../../Navbar/NavBar';
import { getAPI, postAPI } from '../../utils/api'
import { List } from "react-movable";
import Modal from "react-modal";
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [showModal, setShowModal]=useState(false);
  const [selectedUser, setSelectedUser]=useState();
  const [loader, setLoader]=useState(false);

  toast.configure();
  
  

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      borderColor: "neutral10",
      "&:hover": {
        borderColor: "neutral10 !important",
      },
    }),
    overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: "500px",
      width: "100%",
    }
  };

  const getData = () => {
    getAPI(`/user/getUsersTableAll`).then(data => { setUsersData(data.data.data) })
  }
  useEffect(() => {
    getData()
  }, [])
  
  usersData.sort((a, b) => a.id - b.id);

  const handleModalOpen=(email,id)=>{
    setShowModal(true);
  }

  const handleModalClose=()=>{
    setLoader(false)
    setShowModal(false);
    setSelectedUser({})
  }

  const handleChange=(name, value)=>{
     setSelectedUser({
       ...selectedUser,
          [name]:value
      })
   }

   const handleSubmit=(e)=>{
     e.preventDefault()
     if(validator.isEmail(selectedUser.email)){
        setLoader(true)
        postAPI(`/user/updateUserByAdmin`, selectedUser)
         .then(res=>{
           setLoader(false)
           if( res?.data?.status === 1){
            toast.success("Email updated successfully.",{autoClose: 2000})
            handleModalClose()
            getData()}
           else if(res?.data?.status===2){
            toast.error("Email already exist.",{autoClose: 2000});
          }else{
            toast.error("Something went wrong.",{autoClose: 2000});
          }
        })
     }else{
      toast.error("Invalid email.",{autoClose: 2000});
     }
   }

  return (
    <>
      <NavBar />
      <div className="main-content">
        <Header text={"Users List"} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div>
                <span
                  style={{ minWidth: 400 }}
                  className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3"
                >
                </span>
              </div>
              <table className="table table-hover" id="myTable">
                <thead>
                  <tr >
                    <th>#</th>
                    <th scope="col">First_Name</th>
                    <th scope="col">Last_Name</th>
                    <th scope="col">Screen_Name</th>
                    <th scope="col">Email</th>
                    <th className='text-center'>Profile</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <List
                  values={usersData}
                  renderList={({children}) => (
                    <tbody>{children}</tbody>
                  )}
                  renderItem={({value}) => (

                    <tr>
                      <td>{usersData.indexOf(value) + 1}</td>
                      <td><div>{value?.firstName}</div></td>
                      <td><div>{value?.lastName}</div></td>
                      <td>{value?.screenName}</td>
                      <td>{value?.email}</td>
                      <td className='text-center'>{
                          value?.screenName
                          ?
                          <a target="_blank" href={`https://mmabetclub.com/player/${value?.screenName}`}><i className="bi bi-box-arrow-up-right" /></a>
                          :
                          "-"
                      }</td>
                      
                      <td onClick={() => {setSelectedUser(value); handleModalOpen();}}>
                        <i role="button" className='bi bi-pencil-fill'></i>
                      </td>
                    </tr>
                  )}
                />
              </table>
            </div>
          </div>
        </div>
      </div>

      {/*Edit Email Model*/}

      <Modal isOpen={showModal} style={customStyles} onRequestClose={handleModalClose}  ariaHideApp={false}>
     
        <div className="" role="document text-center">
           <div className="w-100 text-center mb-4">
              <h2 className="modal-title" id="exampleModalLabel">{showModal?.edit} Edit User Email</h2>
            </div>

            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={selectedUser?.email} onChange={(e)=>handleChange("email", e.target.value)} placeholder="Enter email"/>
              </div>
              <div className="w-100 d-flex justify-content-center mt-5">
              <button disabled={loader} onClick={handleSubmit} type="submit" class="w-25 btn btn-primary mx-2">
                {loader?
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>:
                "Submit"
                }
                </button>
              <button onClick={handleModalClose} type="submit" class="bg-dark border-0 w-25 btn btn-primary mx-2 ">Close</button>
              </div>
            </form>
        </div>

      </Modal>
    </>
  )
}

export default Users