import React, { useEffect, useState } from "react";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI, postAPI } from "../../utils/api";
import Select from "react-select";
import {getResultNew2} from "../../utils/helper"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import exportFromJSON from "export-from-json";

const UsersBets = (props) => {
  
	const [events, setEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState();
	const [users, setUsers] =  useState([])
	const [selectedUser, setSelectedUser] =  useState()
	
	const [selectedUserEvent, setSelectedUserEvent] = useState()
	const [selectedBetType, setSelectedBetType] = useState({label: "Bets", value:"bets"})
	const [loader, setLoader]= useState({search: false, export: false, exportAllUsers: false});

	const exportType = exportFromJSON.types.csv
	
	const getData = async () => {
		getAPI(`/schedule/getAllSchedule?year=2022`).then((res) => {
			setEvents(res?.data?.data);
			// let latestEvent = res?.data?.data[0];
			// latestEvent = { value: latestEvent?.date_event, label: latestEvent?.event_name, ...latestEvent, };
			// setSelectedEvent(latestEvent);
		});
		getAPI(`/user/getUsersTableAll`).then((res) => {
			setUsers(res.data?.data);
			// let user  = res?.data?.data[0];
			// user = { value: user?.screenName, label: user?.email, ...user };
			// setSelectedUser(user)
		});
	}

	const searchEvent = () => {
		if(selectedUser && selectedEvent){
			setLoader({...loader, search: true})
			const ongoingEvent = events.findIndex(e => (e.event_name === selectedEvent.event_name) && (e.date_event === selectedEvent.date_event)) === 0 ? true : false
			if(selectedBetType?.value === "bets"){
				getAPI(`/user/getUser${ongoingEvent?"":"Past"}BetEventByDate?date=${selectedEvent?.date_event}&eventName=${selectedEvent?.event_name}&userId=${selectedUser?.id}`)
				.then(res => {
					setSelectedUserEvent(res?.data?.data?.sort((a,b) => a.adminIndex - b.adminIndex));
					setLoader({...loader, search: false})
				})
			} else {
				getAPI(`/user/getUser${ongoingEvent?"":"Past"}ParlayEventByDate?date=${selectedEvent?.date_event}&eventName=${selectedEvent?.event_name}&userId=${selectedUser?.id}`)
				.then(res => {
					setSelectedUserEvent(res?.data?.data?.sort((a,b) => a.adminIndex - b.adminIndex));
					setLoader({...loader, search: false})
				})
			}
			
		} else {
			toast.error('Select user and event both.', { position: "top-right", autoClose: 3000 })
		}
		
	}
	useEffect(() => {
		getData()
	}, [])

	const customStyles = {
		control: (base) => ({ ...base, boxShadow: "none", borderColor: "neutral10", "&:hover": { borderColor: "neutral10 !important", }, }),
		overlay: { backgroundColor: "rgb(0 0 0 / 75%)" , zIndex:1111},
		option: (styles, { isFocused, isSelected }) => ({ ...styles, background: isFocused ? "#ceced0" : isSelected ? "#fff" : undefined, color: "gray", }),
		menuPortal: (base) => ({ ...base, zIndex: 9999 }),
		content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', maxWidth: "50%", width: "100%", }
	}

	const eventOptions = events?.map(obj => ({ value: obj?.date_event, label: obj?.event_name, ...obj }))

	const usersOptions = users?.map(obj => ({ value: obj?.screenName, label: obj?.email, ...obj }))

	const betTypeOption = [{label: "Bets", value:"bets"},{label: "Parlays", value:"parlays"}]
	
	const exportThisEvent = () => {
		setLoader({...loader, export: true})
		if(selectedUserEvent && selectedUserEvent.length){
			const exportEvent = []
			selectedUserEvent.map((fight, index) => {
				const awayRow = {
					index: index + 1,
					fighters: fight?.away_team,
					moneyLine: fight?.moneyline_away,
					status: fight?.event_status,
					result: getResultNew2(fight?.result?.winner_away),
					screenName: selectedUser?.screenName,
					email: selectedUser?.email,
					risk: selectedBetType?.value === "bets"  ? fight?.events[0]?.pickedTeam === 2 && !isNaN(fight?.events[0].risk) ? "$"+fight?.events[0].risk : "-"  : fight?.events[0]?.pickedTeam === 2 && !isNaN(fight?.risks?.[0]?.risk) ? "$"+fight?.risks?.[0]?.risk : "-" ,
					riskedMoneyline: fight?.events[0]?.pickedTeam === 2 && fight?.events[0]?.moneyLine ? fight?.events[0]?.moneyLine : "-",
					pickedAt: selectedBetType?.value === "bets" ? fight?.events[0]?.updatedAt ? moment(fight?.events[0]?.updatedAt).format('MM/DD/YYYY HH:mm:ss') : "-" : fight?.events[0]?.updatedAt && !isNaN(fight?.risks?.[0]?.risk)? moment(fight?.events[0]?.updatedAt).format('MM/DD/YYYY HH:mm:ss') : "-"
				}
				const homeRow = {
					index: index + 1,
					fighters: fight?.home_team,
					moneyLine: fight?.moneyline_home,
					status: fight?.event_status,
					result: getResultNew2(fight?.result?.winner_home),
					screenName: selectedUser?.screenName,
					email:selectedUser?.email,
					risk: selectedBetType?.value === "bets" ? fight?.events[0]?.pickedTeam === 1 && !isNaN(fight?.events[0].risk) ? "$"+fight?.events[0].risk : "-"  : fight?.events[0]?.pickedTeam === 1 && !isNaN(fight?.risks?.[0]?.risk) ? "$"+fight?.risks?.[0]?.risk : "-" ,
					riskedMoneyline: fight?.events[0]?.pickedTeam === 1 && fight?.events[0]?.moneyLine ? fight?.events[0]?.moneyLine : "-",
					pickedAt:selectedBetType?.value === "bets" ? fight?.events[0]?.updatedAt ? moment(fight?.events[0]?.updatedAt).format('MM/DD/YYYY HH:mm:ss') : "-" : fight?.events[0]?.updatedAt && !isNaN(fight?.risks?.[0]?.risk)? moment(fight?.events[0]?.updatedAt).format('MM/DD/YYYY HH:mm:ss') : "-"
				}
				exportEvent.push(awayRow)
				exportEvent.push(homeRow)
			})
			const fileName = selectedUser?.screenName + "-" + selectedEvent?.label + "-" + selectedBetType?.label
			exportFromJSON({ data: exportEvent, fileName, exportType })
		}
		else {
			toast.error("Search a user fight event.",{autoClose: 2000})
		}
		setLoader({...loader, export: false})
	}

	const exportAllUsers = () => {
		setLoader({...loader, exportAllUsers: true})
		// exportFromJSON({ data: res.data.data, fileName: "User Detail", exportType })
		setLoader({...loader, exportAllUsers: false})
	}

	return (
		<div>
		<NavBar />
		<div className="main-content">
			<Header text={"User's Bets Data"} />
			<div className="container-fluid">
			<div className="row">
				<div className="col-sm-2">
					<span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3" >
						<Select
							isSearchable={true}
							options={usersOptions}
							onChange={(data) => setSelectedUser(data)}
							value={selectedUser}
							menuPortalTarget={document.body}
							styles={customStyles}
							placeholder="Select or type Users"
						/>
					</span>
				</div>

				<div className="col-sm-2">
					<span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3" >
						<Select
							isSearchable={true}
							options={eventOptions}
							onChange={(data) => setSelectedEvent(data)}
							value={selectedEvent}
							menuPortalTarget={document.body}
							styles={customStyles}
							placeholder="Select or type events"
						/>
					</span>
				</div>

				<div className="col-sm-2">
					<span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3" >
						<Select
							isSearchable={false}
							options={betTypeOption}
							onChange={(data) => setSelectedBetType(data)}
							value={selectedBetType}
							menuPortalTarget={document.body}
							styles={customStyles}
							placeholder="Bet type"
						/>
					</span>
				</div>

				<div className="col-sm-2">
					<button className="btn btn-primary w-100" disabled={loader?.search} onClick={searchEvent}>
					{ loader?.search ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Search"} 
					</button>
				</div>

				<div className="col-sm-2">
					<button className="btn btn-primary w-100" disabled={loader?.export} onClick={exportThisEvent}>
					{ loader?.export ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Export"} 
					</button>
				</div>

				<div className="col-sm-2">
					<button className="btn btn-primary w-100" disabled={loader?.exportAllUsers} onClick={exportAllUsers}>
					{ loader?.exportAllUsers ? <div className="spinner-border spinner-border-sm" role="status"> <span className="sr-only">Loading...</span> </div>:"Export All Users"} 
					</button>
				</div>

			</div>
			<table className="table table-hover" id="myTable">
					<thead>
					<tr >
						<th>#</th>
						<th scope="col">Fighters</th>
						<th scope="col">Moneyline</th>
						<th scope="col">Status</th>
						<th scope="col">Result</th>
						<th scope="col">User</th>
						<th scope="col">Email</th>
						<th scope="col">Risk</th>
						<th scope="col">Risked MoneyLine</th>
						<th scope="col">Picked At</th>
					</tr>
					</thead>
					<tbody>
						{
							selectedUserEvent && selectedUserEvent.length 
							? 
							selectedUserEvent.map((fight, index) => (
								<tr key={index} className={fight?.updatedByAdmin?`my-1 bg-light border rounded shadow`:""}>
									<td>{index + 1}</td>
									<td>
										<div>{fight?.away_team}</div>
										<div>{fight?.home_team}</div>
									</td>
									<td>
										<div>{fight?.moneyline_away}</div>
										<div>{fight?.moneyline_home}</div>
									</td>
									<td>{fight?.event_status}</td>
									<td>
										<div><span className={getResultNew2(fight?.result?.winner_away)==="WIN"?`text-success`:getResultNew2(fight?.result?.winner_away)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(fight?.result?.winner_away)}</span></div>
										<div><span className={getResultNew2(fight?.result?.winner_home)==="WIN"?`text-success`:getResultNew2(fight?.result?.winner_home)==="LOSE"?`text-danger`:"text-secondary"}>{getResultNew2(fight?.result?.winner_home)}</span></div>
									</td>
									<td>
										<a target="_blank" href={`https://mmabetclub.com/player/${selectedUser?.screenName}`}>{selectedUser?.screenName}</a>
									</td>
									<td>{selectedUser?.email}</td>
									{
										selectedBetType?.value === "bets"
										?
										<td>
											<div>{fight?.events[0]?.pickedTeam === 2 && !isNaN(fight?.events[0].risk) ? "$"+fight?.events[0].risk : "-" }</div>
											<div>{fight?.events[0]?.pickedTeam === 1 && !isNaN(fight?.events[0].risk) ? "$"+fight?.events[0].risk : "-" }</div>
										</td>
										:
										<td>
											<div>{ fight?.events[0]?.pickedTeam === 2 && !isNaN(fight?.risks?.[0]?.risk) ? "$"+fight?.risks?.[0]?.risk : "-" }</div>
											<div>{ fight?.events[0]?.pickedTeam === 1 && !isNaN(fight?.risks?.[0]?.risk) ? "$"+fight?.risks?.[0]?.risk : "-" }</div>
										</td>
									}
									<td>{fight?.events[0]?.moneyLine ? fight?.events[0]?.moneyLine : "-" }</td>
									{
										selectedBetType?.value === "bets"
										?
										<td>
											{fight?.events[0]?.updatedAt ? moment(fight?.events[0]?.updatedAt).format('MM/DD/YYYY HH:mm:ss') : "-" }
										</td>
										:
										<td>
											{fight?.events[0]?.updatedAt && !isNaN(fight?.risks?.[0]?.risk)? moment(fight?.events[0]?.updatedAt).format('MM/DD/YYYY HH:mm:ss') : "-" }
										</td>
									}
								</tr>
							))
							:
							""
						}
						
					</tbody>
				</table>
			</div>
		</div>
		<ToastContainer />
	</div>
  )
}

export default UsersBets
